import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import { api, header_token } from "../../config";
import moment from "moment";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";

import "moment/locale/th";

function CustomOverlay({ classNames, selectedDay, children, ...props }) {
  return (
    <div
      className={classNames.overlayWrapper}
      style={{ marginLeft: -109 }}
      {...props}
    >
      <div className={classNames.overlay}>{children}</div>
    </div>
  );
}

export const RestaurantDetailSale = (props) => {
  const [loading, setloading] = useState(false);

  const [data, setdata] = useState([]);
  const [current_page, setcurrent_page] = useState(1);
  const [page_count, setpage_count] = useState(0);
  const [limit] = useState(10);
  const [total_count, settotal_count] = useState(0);

  const [filter_order] = useState("");
  const [filter_from, setfilter_from] = useState(moment().format("DD/MM/YYYY"));
  const [filter_to, setfilter_to] = useState(moment().format("DD/MM/YYYY"));

  const getData = async () => {
    setloading(true);
    try {
      let start = moment(filter_from, "DD/MM/YYYY").format("YYYY-MM-DD");
      let end = moment(filter_to, "DD/MM/YYYY").format("YYYY-MM-DD");

      let send_data = {
        restaurant_id: props.restaurantId,
        end_date: end,
        order_no: filter_order,
        start_date: start,
        // status: -1,
      };

      let { data } = await Axios.post(
        `${api}/admin/order/filter?page=${current_page}&limit=${limit}&sort=${-1}`,
        send_data,
        header_token
      );

      // console.log("data", data);
      if (data.orders !== null) {
        let cal_page_count = Math.ceil(data.meta.total_count / limit);
        setpage_count(cal_page_count);
        let sum = 0;
        data.orders.forEach((value) => {
          sum += value.food_cost;
        });
        settotal_count(sum);

        setdata(data.orders);
      }
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setloading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setcurrent_page(1);
    getData();
  }, [filter_from, filter_to, filter_order]);

  useEffect(() => {
    getData();
  }, [current_page]);

  return (
    <div className="card-body">
      <div className="row">
        <h2 className="mt-2 ml-2">{total_count.toLocaleString()}</h2>

        <div className="col-10 m-1 ml-auto">
          <div className="InputFromTo" style={{ float: "right" }}>
            <DayPickerInput
              value={filter_from}
              onDayChange={(e) => {
                setfilter_from(e);
              }}
              placeholder={"วัน/เดือน/ปี"}
              format="L"
              formatDate={formatDate}
              parseDate={parseDate}
              dayPickerProps={{
                locale: "th",
                localeUtils: MomentLocaleUtils,
              }}
            />{" "}
            ถึง{" "}
            <span className="InputFromTo-to">
              <DayPickerInput
                value={filter_to}
                onDayChange={(e) => {
                  setfilter_to(e);
                }}
                overlayComponent={CustomOverlay}
                placeholder={"วัน/เดือน/ปี"}
                format="L"
                formatDate={formatDate}
                parseDate={parseDate}
                dayPickerProps={{
                  locale: "th",
                  localeUtils: MomentLocaleUtils,
                }}
              />
            </span>
          </div>
        </div>
      </div>
      <LoadingMask loading={loading} text={"loading..."}>
        <div style={{ minHeight: 500 }}>
          <table id="dataTable" className="table">
            <thead>
              <tr>
                <th>Order No.</th>
                <th>ยอดขาย</th>
                <th>สถานะ</th>
                <th>วันที่</th>
              </tr>
            </thead>
            <tbody>
              {data.map((value, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <a
                        tabIndex={0}
                        onClick={() => {
                          props.pushToLink(`/order/detail/${value.id}`);
                        }}
                      >
                        {value.order_no}
                      </a>
                    </td>
                    <td>{value.food_cost.toLocaleString("de")}</td>
                    <td>
                      {(() => {
                        if (value.type === 0) return "ออเดอร์ล่วงหน้า";

                        switch (value.status) {
                          case 0:
                            return "ยกเลิก";
                          case 1:
                            return "ออเดอร์ใหม่";
                          case 4:
                            return "กำลังจัดส่ง";
                          case 5:
                            return "ส่งสำเร็จ";
                          case 6:
                            return "ส่งไม่สำเร็จ";

                          default:
                            break;
                        }
                      })()}
                    </td>
                    <td>{moment(value.created_at).format("DD/MM/YYYY")}</td>
                  </tr>
                );
              })}

              {/* <tr>
            <td></td>
            <td>
              <a href="order_detail.php">00000001</a>{" "}
            </td>
            <td>86,000</td>
            <td>ส่งเรียบร้อย</td>
            <td>2011/04/25</td>
          </tr> */}
            </tbody>
          </table>
          <div style={{ float: "right" }}>
            <ReactPaginate
              previousLabel={"← Previous"}
              nextLabel={"Next →"}
              breakLabel={<span className="gap">...</span>}
              pageCount={page_count}
              onPageChange={(e) => setcurrent_page(e.selected + 1)}
              forcePage={current_page - 1}
              containerClassName={"pagination"}
              previousLinkClassName={"previous_page"}
              nextLinkClassName={"next_page"}
              disabledClassName={"disabled"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </LoadingMask>
    </div>
  );
};
