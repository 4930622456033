import React, { useState } from "react";
import { Switch, Redirect } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";

import { NotFound } from "./NotFound";
import { Topbar } from "./Components/Topbar";
import { Sidebar } from "./Components/Sidebar";
import { Dashboard } from "./Components/Dashboard";
import { UserAll } from "./Components/User/UserAll";
import { RiderAll } from "./Components/Rider/RiderAll";
import { RestaurantApprove } from "./Components/Restaurant/RestaurantApprove";
import { RestaurantAll } from "./Components/Restaurant/RestaurantAll";
import { RestaurantEdit } from "./Components/Restaurant/RestaurantEdit";
import { RestaurantDetail } from "./Components/Restaurant/RestaurantDetail";
import { RestaurantDetailMenuAdd } from "./Components/Restaurant/RestaurantDetailMenuAdd";
import { OrderIndex } from "./Components/Order/OrderIndex";
import { SendingIndex } from "./Components/Sending/SendingIndex";
import { SaleBillIndex } from "./Components/SaleBill/SaleBillIndex";
import { CreditIndex } from "./Components/Credit/CreditIndex";
import { NewsIndex } from "./Components/News/NewsIndex";
import { SettingIndex } from "./Components/Setting/SettingIndex";
import { RestaurantDetailMenuEdit } from "./Components/Restaurant/RestaurantDetailMenuEdit";
import { RiderAdd } from "./Components/Rider/RiderAdd";
import { RiderTeam } from "./Components/Rider/RiderTeam";
import { RiderDetailHistory } from "./Components/Rider/RiderDetailHistory";
import { RiderDetailCredit } from "./Components/Rider/RiderDetailCredit";
import { RiderDetailWithdraw } from "./Components/Rider/RiderDetailWithdraw";
import { RiderDetailService } from "./Components/Rider/RiderDetailService";
import { RiderEdit } from "./Components/Rider/RiderEdit";
import { UserAdd } from "./Components/User/UserAdd";
import { UserEdit } from "./Components/User/UserEdit";
import { RestaurantAdd } from "./Components/Restaurant/RestaurantAdd";
import { CreditHistory } from "./Components/Credit/CreditHistory";
import { WithdrawHead } from "./Components/Withdraw/WithdrawHead";
import { OrderDetail } from "./Components/Order/OrderDetail";
import { RiderApprove } from "./Components/Rider/RiderApprove";
import { NotificationFirebase } from "./store/NotificationFirebase";
import { RecommenedHead } from "./Components/Recommened/RecommenedHead";
import { PromotionBannerHead } from "./Components/PromotionBanner/PromotionBannerHead";
import { RestaurantPromotionHead } from "./Components/RestaurantPromotion/RestaurantPromotionHead";
import { AdminProfile } from "./Components/AdminProfile";
import { UserOrderHistory } from "./Components/User/UserOrderHistory";
import { NewsAdd } from "./Components/News/NewsAdd";
import { RiderImport } from "./Components/Rider/RiderImport";
import { NewsEdit } from "./Components/News/NewsEdit";
import { ReadNews } from "./ReadNews";

import { NotiContext, RulesContext } from "./store/keep";
import { UserImport } from "./Components/User/UserImport";
import { RestaurantImport } from "./Components/Restaurant/RestaurantImport";
import { BranchAll } from "./Components/Branch/BranchAll";
import { BranchSaleFood } from "./Components/Branch/BranchSaleFood";
import { BranchSaleShipping } from "./Components/Branch/BranchSaleShipping";
import { BranchAdd } from "./Components/Branch/BranchAdd";
import { BranchDetail } from "./Components/Branch/BranchDetail";
import { BranchEdit } from "./Components/Branch/BranchEdit";
import { PromotionCodeHead } from "./Components/PromotionCode/PromotionCodeHead";
import { CarServiceIndex } from "./Components/CarService/CarServiceIndex";
import { CarServiceAdd } from "./Components/CarService/CarServiceAdd";
import { CarServiceEdit } from "./Components/CarService/CarServiceEdit";
import { OrderCarIndex } from "./Components/OrderCarService/OrderCarIndex";
import { OrderCarDetail } from "./Components/OrderCarService/OrderCarDetail";
import { SaleBillShippingIndex } from "./Components/SaleBill/SaleBillShippingIndex";
import { ServiceFeeGroup } from "./Components/ServiceFee/ServiceFeeGroup";
import { ServiceFeeAll } from "./Components/ServiceFee/ServiceFeeAll";
import { ServiceFeeRecommend } from "./Components/ServiceFee/ServiceFeeRecommend";
import { ServiceFeeGroupAdd } from "./Components/ServiceFee/ServiceFeeGroupAdd";
import { ServiceFeeAdd } from "./Components/ServiceFee/ServiceFeeAdd";
import { ServiceFeeRecommendAdd } from "./Components/ServiceFee/ServiceFeeRecommendAdd";
import { ServiceFeeGroupEdit } from "./Components/ServiceFee/ServiceFeeGroupEdit";
import { ServiceFeeRecommendEdit } from "./Components/ServiceFee/ServiceFeeRecommendEdit";
import { ServiceFeeEdit } from "./Components/ServiceFee/ServiceFeeEdit";
import { OrderServiceIndex } from "./Components/OrderService/OrderServiceIndex";
import { OrderServiceDetail } from "./Components/OrderService/OrderServiceDetail";
import { SaleBillServiceIndex } from "./Components/SaleBill/SaleBillServiceIndex";
import { UserServiceEdit } from "./Components/UserService/UserServiceEdit";
import { UserServiceDetailHistory } from "./Components/UserService/UserServiceDetailHistory";
import { UserServiceDetailService } from "./Components/UserService/UserServiceDetailService";
import { UserServiceDetailWithdraw } from "./Components/UserService/UserServiceDetailWithdraw";
import { UserServiceDetailCredit } from "./Components/UserService/UserServiceDetailCredit";
import { UserServiceApprove } from "./Components/UserService/UserServiceApprove";
import { UserServiceAdd } from "./Components/UserService/UserServiceAdd";
import { UserServiceAll } from "./Components/UserService/UserServiceAll";
import { BranchSaleService } from "./Components/Branch/BranchSaleService";

export const Admin = ({ token: token }) => {
  const [rules, setrules] = useState({
    banner: false,
    deposit: false,
    member: false,
    news: false,
    order: false,
    promotion: false,
    recommend: false,
    restaurant: false,
    rider: false,
    setting: false,
    shipping: false,
    summary: false,
    withdraw: false,
    branch: false,
    car_service: false,
    car_service_order: false,
  });

  const [noti, setnoti] = useState({
    rider: 0,
    restuarant: 0,
    order: 0,
    withdraw: 0,
    credit: 0,
    service: 0,
    getdata: "",
  });

  // useEffect(() => {
  //   console.log('rules',rules);
  // }, [rules]);

  return (
    <RulesContext.Provider value={{ rules, setrules }}>
      <NotiContext.Provider value={{ noti, setnoti }}>
        <div className="container-scroller">
          <Topbar />
          <div className="container-fluid page-body-wrapper">
            <Sidebar />
            <div className="main-panel">
              <div className="content-wrapper">
                <NotificationFirebase />

                <Switch>
                  <PrivateRoute
                    token={token}
                    path={`/news/read/:id`}
                    component={ReadNews}
                    isAllowed={true}
                  />
                  {/* -------------------------------------- User -------------------------------------- */}
                  <PrivateRoute
                    token={token}
                    path={`/user/order/:id`}
                    component={UserOrderHistory}
                    isAllowed={rules.member}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/user/edit/:id`}
                    component={UserEdit}
                    isAllowed={rules.member}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path="/user/add"
                    component={UserAdd}
                    isAllowed={rules.member}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path="/user"
                    component={UserAll}
                    isAllowed={rules.member}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path="/user/import"
                    component={UserImport}
                    isAllowed={rules.member}
                  />
                  {/* -------------------------------------- Car Service --------------------------------------  */}
                  {/* <PrivateRoute
                    exact
                    token={token}
                    path="/car_service"
                    component={CarServiceIndex}
                    isAllowed={rules.car_service_setting}
                  />

                  <PrivateRoute
                    exact
                    token={token}
                    path="/car_service/add"
                    component={CarServiceAdd}
                    isAllowed={rules.car_service_setting}
                  />

                  <PrivateRoute
                    exact
                    token={token}
                    path="/car_service/edit/:service_id"
                    component={CarServiceEdit}
                    isAllowed={rules.car_service_setting}
                  /> */}

                  {/* -------------------------------------- Service Fee Setting -------------------------------------- */}
                  {/* <PrivateRoute
                    exact
                    token={token}
                    path="/service_fee/group"
                    component={ServiceFeeGroup}
                    isAllowed={rules.service_setting}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path="/service_fee/group/add"
                    component={ServiceFeeGroupAdd}
                    isAllowed={rules.service_setting}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path="/service_fee/group/edit/:service_id"
                    component={ServiceFeeGroupEdit}
                    isAllowed={rules.service_setting}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path="/service_fee"
                    component={ServiceFeeAll}
                    isAllowed={rules.service_setting}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path="/service_fee/add"
                    component={ServiceFeeAdd}
                    isAllowed={rules.service_setting}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path="/service_fee/edit/:service_id"
                    component={ServiceFeeEdit}
                    isAllowed={rules.service_setting}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path="/service_fee/recommend"
                    component={ServiceFeeRecommend}
                    isAllowed={rules.service_setting}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path="/service_fee/recommend/add"
                    component={ServiceFeeRecommendAdd}
                    isAllowed={rules.service_setting}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path="/service_fee/recommend/edit/:service_id"
                    component={ServiceFeeRecommendEdit}
                    isAllowed={rules.service_setting}
                  /> */}

                  {/* -------------------------------------- Service Fee Order -------------------------------------- */}

                  {/* <PrivateRoute
                    exact
                    token={token}
                    path="/service_fee_order/detail/:id"
                    component={OrderServiceDetail}
                    isAllowed={rules.service}
                  />

                  <PrivateRoute
                    token={token}
                    path="/service_fee_order"
                    component={OrderServiceIndex}
                    isAllowed={rules.service}
                  /> */}

                  {/* -------------------------------------- Branch --------------------------------------  */}
                  {/* <PrivateRoute
                    token={token}
                    path="/branch/detail/:id"
                    component={BranchDetail}
                    isAllowed={rules.branch}
                  />
                  <PrivateRoute
                    token={token}
                    path="/branch/edit/:id"
                    component={BranchEdit}
                    isAllowed={rules.branch}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path="/branch/add"
                    component={BranchAdd}
                    isAllowed={rules.branch}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path="/branch/sale"
                    component={BranchSaleFood}
                    isAllowed={rules.branch}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path="/branch/sale/shipping"
                    component={BranchSaleShipping}
                    isAllowed={rules.branch}
                  />

                  <PrivateRoute
                    exact
                    token={token}
                    path="/branch/sale/service"
                    component={BranchSaleService}
                    isAllowed={rules.branch}
                  />

                  <PrivateRoute
                    token={token}
                    path="/branch"
                    component={BranchAll}
                    isAllowed={rules.branch}
                  /> */}
                  {/* -------------------------------------- Restaurant --------------------------------------  */}
                  <PrivateRoute
                    token={token}
                    path="/restaurant/detail/:id/menu-add"
                    component={RestaurantDetailMenuAdd}
                    isAllowed={rules.restaurant}
                  />
                  <PrivateRoute
                    token={token}
                    path="/restaurant/detail/:id/menu-edit/:menu_id"
                    component={RestaurantDetailMenuEdit}
                    isAllowed={rules.restaurant}
                  />
                  <PrivateRoute
                    token={token}
                    path="/restaurant/detail/:id"
                    component={RestaurantDetail}
                    isAllowed={rules.restaurant}
                  />
                  <PrivateRoute
                    token={token}
                    path="/restaurant/edit/:id"
                    component={RestaurantEdit}
                    isAllowed={rules.restaurant}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/restaurant/approve`}
                    component={RestaurantApprove}
                    isAllowed={rules.restaurant}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/restaurant/add`}
                    component={RestaurantAdd}
                    isAllowed={rules.restaurant}
                  />
                  <PrivateRoute
                    token={token}
                    path="/restaurant/import"
                    component={RestaurantImport}
                    isAllowed={rules.restaurant}
                  />
                  <PrivateRoute
                    token={token}
                    path="/restaurant"
                    component={RestaurantAll}
                    isAllowed={rules.restaurant}
                  />
                  {/* -------------------------------------- Rider --------------------------------------  */}
                  <PrivateRoute
                    token={token}
                    path={`/rider/import`}
                    component={RiderImport}
                    isAllowed={rules.rider}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/rider/edit/:id`}
                    component={RiderEdit}
                    isAllowed={rules.rider}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/rider/history/:id`}
                    component={RiderDetailHistory}
                    isAllowed={rules.rider}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/rider/service/:id`}
                    component={RiderDetailService}
                    isAllowed={rules.rider}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/rider/withdraw/:id`}
                    component={RiderDetailWithdraw}
                    isAllowed={rules.rider}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/rider/credit/:id`}
                    component={RiderDetailCredit}
                    isAllowed={rules.rider}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path={`/rider/team`}
                    component={RiderTeam}
                    isAllowed={rules.rider}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path={`/rider/approve`}
                    component={RiderApprove}
                    isAllowed={rules.rider}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path={`/rider/add`}
                    component={RiderAdd}
                    isAllowed={rules.rider}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path={`/rider`}
                    component={RiderAll}
                    isAllowed={rules.rider}
                  />
                  {/* -------------------------------------- User Service --------------------------------------  */}

                  {/* <PrivateRoute
                    token={token}
                    path={`/user_service/edit/:id`}
                    component={UserServiceEdit}
                    isAllowed={rules.user_service}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/user_service/history/:id`}
                    component={UserServiceDetailHistory}
                    isAllowed={rules.user_service}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/user_service/service/:id`}
                    component={UserServiceDetailService}
                    isAllowed={rules.user_service}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/user_service/withdraw/:id`}
                    component={UserServiceDetailWithdraw}
                    isAllowed={rules.user_service}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/user_service/credit/:id`}
                    component={UserServiceDetailCredit}
                    isAllowed={rules.user_service}
                  />

                  <PrivateRoute
                    exact
                    token={token}
                    path={`/user_service/approve`}
                    component={UserServiceApprove}
                    isAllowed={rules.user_service}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path={`/user_service/add`}
                    component={UserServiceAdd}
                    isAllowed={rules.user_service}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path={`/user_service`}
                    component={UserServiceAll}
                    isAllowed={rules.user_service}
                  /> */}
                  {/* -------------------------------------- Order -------------------------------------- */}
                  <PrivateRoute
                    exact
                    token={token}
                    path="/order/detail/:id"
                    component={OrderDetail}
                    isAllowed={rules.order}
                  />
                  <PrivateRoute
                    token={token}
                    path="/order"
                    component={OrderIndex}
                    isAllowed={rules.order}
                  />
                  {/* -------------------------------------- Car Service Order -------------------------------------- */}
                  {/* <PrivateRoute
                    exact
                    token={token}
                    path="/car_service_order/detail/:id"
                    component={OrderCarDetail}
                    isAllowed={rules.car_service}
                  />
                  <PrivateRoute
                    token={token}
                    path="/car_service_order"
                    component={OrderCarIndex}
                    isAllowed={rules.car_service}
                  /> */}
                  {/* -------------------------------------- Sending -------------------------------------- */}
                  {/* <PrivateRoute
                    token={token}
                    path="/sending"
                    component={SendingIndex}
                    isAllowed={rules.shipping}
                  /> */}
                  {/* -------------------------------------- Credit -------------------------------------- */}
                  <PrivateRoute
                    exact
                    token={token}
                    path="/credit/history"
                    component={CreditHistory}
                    isAllowed={rules.deposit}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path="/credit"
                    component={CreditIndex}
                    isAllowed={rules.deposit}
                  />
                  {/* -------------------------------------- Withdraw  -------------------------------------- */}
                  <PrivateRoute
                    token={token}
                    path="/withdraw"
                    component={WithdrawHead}
                    isAllowed={rules.withdraw}
                  />
                  {/* --------------------------------------  News  -------------------------------------- */}
                  <PrivateRoute
                    exact
                    token={token}
                    path="/news"
                    component={NewsIndex}
                    isAllowed={rules.news}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path="/news/add"
                    component={NewsAdd}
                    isAllowed={rules.news}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path="/news/:id"
                    component={NewsEdit}
                    isAllowed={rules.news}
                  />
                  {/* -------------------------------------- Promotion Code --------------------------------------  */}
                  {/* <PrivateRoute
                    token={token}
                    path="/promotion_code"
                    component={PromotionCodeHead}
                    isAllowed={rules.promotion_code}
                  /> */}
                  {/* --------------------------------------  Others  -------------------------------------- */}
                  <PrivateRoute
                    token={token}
                    path="/setting"
                    component={SettingIndex}
                    isAllowed={rules.setting}
                  />
                  <PrivateRoute
                    token={token}
                    path="/recommened"
                    component={RecommenedHead}
                    isAllowed={rules.recommend}
                  />
                  <PrivateRoute
                    token={token}
                    path="/promotion_banner"
                    component={PromotionBannerHead}
                    isAllowed={rules.banner}
                  />
                  <PrivateRoute
                    token={token}
                    path="/restaurant_promotion"
                    component={RestaurantPromotionHead}
                    isAllowed={rules.promotion}
                  />
                  <PrivateRoute
                    token={token}
                    path="/sale_bill"
                    exact
                    component={SaleBillIndex}
                    isAllowed={rules.summary}
                  />
                  {/* <PrivateRoute
                    token={token}
                    path="/sale_bill/shipping"
                    component={SaleBillShippingIndex}
                    isAllowed={rules.summary}
                  />
                  <PrivateRoute
                    token={token}
                    path="/sale_bill/service"
                    component={SaleBillServiceIndex}
                    isAllowed={rules.summary}
                  /> */}
                  <PrivateRoute
                    token={token}
                    path="/admin_profile"
                    component={AdminProfile}
                    isAllowed={true}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path="/"
                    component={Dashboard}
                    isAllowed={true}
                  />
                  <PrivateRoute
                    token={token}
                    path="*"
                    component={NotFound}
                    isAllowed={true}
                  />
                  <Redirect to="/404" />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </NotiContext.Provider>
    </RulesContext.Provider>
  );
};
