import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import Axios from "axios";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import { api, header_token } from "../../config";
import moment from "moment";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";

export const UserOrderHistory = () => {
  const { id } = useParams();
  const checkAllRef = useRef(null);

  const [loading, setloading] = useState(false);

  const [user, setuser] = useState({});
  const [data, setdata] = useState([]);
  const [current_page, setcurrent_page] = useState(1);
  const [page_count, setpage_count] = useState(0);
  const [limit] = useState(10);

  const getProfile = async () => {
    try {
      let { data } = await Axios.get(`${api}/user/profile/${id}`, header_token);

      // console.log("getProfile", data);
      setuser(data);
    } catch (error) {
      // console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const getData = async () => {
    setloading(true);
    try {
      let send_data = {
        user_id: id,
        end_date: "",
        // order_no: filter_order,
        start_date: "",
        service: ["service", "food", "car"],
        // status: -1,
      };

      let { data } = await Axios.post(
        `${api}/admin/order/filter?page=${current_page}&limit=${limit}&sort=${-1}&sort_by=${"created_at"}`,
        send_data,
        header_token
      );

      // console.log("data", data);
      if (data.orders !== null) {
        let cal_page_count = Math.ceil(data.meta.total_count / limit);
        setpage_count(cal_page_count);

        setdata(data.orders);
      }
    } catch (error) {
      // console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setloading(false);
  };

  useEffect(() => {
    getData();
  }, [current_page]);

  useEffect(() => {
    getData();
    getProfile();
  }, []);

  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-2">Order History</h4>
            <div className="d-none d-md-flex mt-1">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าแรก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">
                Order History
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col">
                  <h4 className="mt-2">{user.full_name}</h4>
                  <h6 className="text-muted">ID {user.user_id}</h6>
                </div>

                <div className="ml-auto">
                  <div style={{ width: 120 }} className="">
                    {/* <span className="tx-13 text-muted">ดำเนินการ</span>
                    <select className="form-control- ">
                      <option value="">เลือก</option>
                      <option value="">ลบ</option>
                      <option value="">Export</option>
                    </select> */}
                  </div>
                </div>
                <div style={{ width: 130 }}>
                  {/* <a href="" id="search" className="btn btn-primary ml-2"
                                                style="margin-top: 22px;">Export All</a> */}
                </div>
              </div>
              <LoadingMask loading={loading} text={"loading..."}>
                <div style={{ minHeight: 500 }}>
                  <table id="dataTable" className="table">
                    <thead>
                      <tr>
                        <th width="30">
                          <input
                            type="checkbox"
                            className="selectAll "
                            name="selectAll"
                            defaultChecked={false}
                            ref={checkAllRef}
                            onClick={(e) => {
                              // // console.log(checkAllRef.current.checked);
                              let tmp = [...data];
                              let _tmp = [];
                              tmp.forEach((value, idx) => {
                                value.checked = checkAllRef.current.checked;
                                _tmp.push(value);
                              });
                              setdata(_tmp);
                              // // console.log(data);
                            }}
                          />
                        </th>
                        <th>Order No</th>
                        {/* <th>ค่าอาหาร</th>
                        <th>ค่าส่ง</th> */}
                        <th>ประเภท</th>
                        <th>วันที่</th>
                        {/* <th>สถานะ</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((value, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <input
                                type="checkbox"
                                checked={value.checked || ""}
                                onChange={(e) => {
                                  let tmp = [...data];
                                  data.forEach((vl, idx) => {
                                    if (vl.id === value.id) {
                                      tmp[idx].checked = !tmp[idx].checked;
                                      setdata(tmp);
                                    }
                                  });
                                }}
                              />
                            </td>
                            <td>
                              <Link
                                to={`/${
                                  value.service === "food"
                                    ? "order"
                                    : value.service === "car"
                                    ? "car_service_order"
                                    : "service_fee_order"
                                }/detail/${value.id}`}
                              >
                                {value.order_no}
                              </Link>
                            </td>
                            <td>
                              {value.service === "food"
                                ? "อาหาร"
                                : value.service === "car"
                                ? "เรียกรถ"
                                : "บริการ"}
                            </td>
                            {/* <td>{value.food_cost.toLocaleString("de")}</td>
                            <td>{value.shipping_cost.toLocaleString("de")}</td> */}
                            <td>
                              {moment(value.created_at).format("DD/MM/YYYY")}
                            </td>
                            {/* <td>
                              {(() => {
                                if (value.type === 0) return "ออเดอร์ล่วงหน้า";

                                switch (value.status) {
                                  case 0:
                                    return "ยกเลิก";
                                  case 1:
                                    return "ออเดอร์ใหม่";
                                  case 2:
                                    return "พนักงานรับออเดอร์";
                                  case 3:
                                    return "ดำเนินการ";
                                  case 4:
                                    return "กำลังจัดส่ง";
                                  case 5:
                                    return "ส่งสำเร็จ";
                                  case 6:
                                    return "ส่งไม่สำเร็จ";

                                  default:
                                    break;
                                }
                              })()}
                            </td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div style={{ float: "right" }}>
                    <ReactPaginate
                      previousLabel={"← Previous"}
                      nextLabel={"Next →"}
                      breakLabel={<span className="gap">...</span>}
                      pageCount={page_count}
                      onPageChange={(e) => setcurrent_page(e.selected + 1)}
                      forcePage={current_page - 1}
                      containerClassName={"pagination"}
                      previousLinkClassName={"previous_page"}
                      nextLinkClassName={"next_page"}
                      disabledClassName={"disabled"}
                      activeClassName={"active"}
                    />
                  </div>
                  <br />
                </div>
              </LoadingMask>
            </div>
          </div>
        </div>
      </div>
      {/* 
      <div>
        <div className="row mt-3">
          <a href="coupon.php" className="btn btn-light ml-auto mr-2">
            ยกเลิก
          </a>
          <a href="coupon.php" type="submit" className="btn btn-primary mr-2">
            บันทึกข้อมูล
          </a>
        </div>
      </div> */}
    </div>
  );
};
