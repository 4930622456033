import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";

import "moment/locale/th";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import swal from "sweetalert";
import Axios from "axios";
import { api, header_token, img_url } from "../../config";
import Select from "react-select";
import { useParams } from "react-router-dom";

function CustomOverlay({ classNames, selectedDay, children, ...props }) {
  return (
    <div
      className={classNames.overlayWrapper}
      style={{ marginTop: -340, backgroundColor: "red" }}
      {...props}
    >
      <div className={classNames.overlay}>{children}</div>
    </div>
  );
}

const option_send = [
  { value: "user", label: "สมาชิก" },
  { value: "rider", label: "พนักงานขับรถ" },
  { value: "restaurant", label: "ร้านอาหาร" },
  { value: "service", label: "พนักงานบริการ" },
];

export const NewsEdit = () => {
  const { id } = useParams();

  const { register, handleSubmit, errors, trigger } = useForm();

  const [title, settitle] = useState("");
  const [detail, setdetail] = useState(EditorState.createEmpty());
  const [cover, setcover] = useState([]);
  const [effective_day, seteffective_day] = useState(new Date());
  const [effective_time, seteffective_time] = useState("00:00");
  const [note, setnote] = useState("");
  const [status, setstatus] = useState("");
  const [send_to, setsend_to] = useState([]);

  const uploadImageCallBack = async (file) => {
    let uploadedImages = cover;

    const imageObject = {
      file: file,
      localSrc: URL.createObjectURL(file),
      base64: await toBase64(file),
    };

    uploadedImages.push(imageObject);

    // setcover((prev) => [...prev, uploadedImages]);
    return new Promise((resolve, reject) => {
      resolve({ data: { link: imageObject.base64 } });
    });
  };

  const onEditorStateChange = (editorState) => {
    setdetail(editorState);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const editNews = async (send_data) => {
    try {
      // console.log(send_data);
      let res = await Axios.put(
        `${api}/admin/news/${id}`,
        send_data,
        header_token
      );

      // console.log(res);
      if (res.status === 200) {
        swal("แก้ไขข่าวเสร็จสิ้น !!", {
          icon: "success",
        });
      } else {
        swal("ผิดพลาด !!", {
          icon: "error",
        });
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const onSubmit = (data) => {
    // console.log(send_to);
    let ef = moment(effective_day, "DD/MM/YYYY").format("YYYY-MM-DD");

    let send_ = {
      cover: cover.base64 ? cover.base64 : cover.preview,
      description: draftToHtml(convertToRaw(detail.getCurrentContent())),
      effective_date: `${ef} ${effective_time + ":00"}`,
      note: note,
      for_roles: send_to.map((e) => e.value),
      title: data.title,
      status: status,
    };

    editNews(send_);
    // console.log(send_);
  };

  const getData = async () => {
    try {
      let { data } = await Axios.get(`${api}/admin/news/${id}`, header_token);
      // console.log("from get", data);
      settitle(data.title);
      setcover({
        preview: data.cover,
        base64: null,
      });

      let time = moment(data.effective_date, "YYYY-MM-DD​ h:mm:s").format(
        "HH:mm"
      );
      seteffective_time(time);
      let eff = new Date(data.effective_date);
      seteffective_day(eff);

      const blocksFromHtml = htmlToDraft(data.description);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );

      setdetail(EditorState.createWithContent(contentState));

      let tmp = [];
      option_send.filter((v) => {
        data.for_roles.forEach((e) => {
          if (v.value === e) {
            tmp.push(v);
          }
          return;
        });
      });

      setsend_to(tmp);
      setnote(data.note);
      setstatus(data.status);
      // const [detail, setdetail] = useState(EditorState.createEmpty());
      // const [cover, setcover] = useState([]);
      // const [effective_day, seteffective_day] = useState(new Date());
      // const [effective_time, seteffective_time] = useState("00:00");
      // const [note, setnote] = useState("");
      // const [send_to, setsend_to] = useState([]);

      // setdata(tmp);
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div className="content-wrapper">
        <div className="dashboard-header d-flex flex-column grid-margin">
          <div className="d-flex align-items-center justify-content-between flex-wrap ">
            <div className="d-flex align-items-center">
              <h4 className="mb-0 font-weight-bold mr-2">ประกาศข่าว</h4>
              <div className="d-none d-md-flex mt-1">
                <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าแรก</p>
                <i className="mdi mdi-chevron-right text-muted"></i>
                <p className="text-muted mb-0 tx-13 cursor-pointer">
                  ประกาศข่าว
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group row">
                    <label
                      htmlFor="title-html"
                      className="col-sm-3 col-form-label"
                    >
                      หัวข้อ
                    </label>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        name="title"
                        defaultValue={title}
                        // onChange={(e) => {
                        //   settitle(e.target.value);
                        // }}
                        className={`form-control ${
                          errors.title ? "error-input" : ""
                        }`}
                        ref={register({ required: true })}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="uplaod-cover"
                      className="col-sm-3 col-form-label"
                    >
                      อัพโหลดรูปภาพ
                    </label>
                    <div className="col-sm-5">
                      <div className="row">
                        <div className="col">
                          <img
                            id="img-upload"
                            src={
                              cover.preview
                                ? `${img_url}${cover.preview}`
                                : cover.base64
                            }
                            style={{ maxWidth: "100%", maxHeight: 500 }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-10">
                          <div className="input-group">
                            <input
                              type="file"
                              className="form-control"
                              className={` ${
                                errors.cover ? "error-img-upload" : ""
                              }`}
                              accept=".png, .jpg, .jpeg"
                              name="cover"
                              onChange={async (e) => {
                                if (e.target.files.length) {
                                  await setcover({
                                    // preview: URL.createObjectURL(
                                    //   e.target.files[0]
                                    // ),
                                    preview: null,
                                    base64: await toBase64(e.target.files[0]),
                                  });

                                  // trigger("cover");
                                }
                              }}
                              style={{
                                width: "100%",
                                overflowWrap: "break-word",
                                display: "-webkit-inline-box",
                              }}
                              // ref={(e) => {
                              //   register(
                              //     { name: "cover" },
                              //     {
                              //       validate: () => {
                              //         return cover.preview || cover.base64
                              //           ? true
                              //           : false;
                              //       },
                              //     }
                              //   );
                              // }}
                            />
                          </div>
                        </div>

                        <div className="col">
                          <button
                            className="btn btn-light btn-sm mt-1"
                            onClick={async () => {
                              await setcover({
                                preview: null,
                                base64: null,
                              });
                              // trigger("cover");
                            }}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="detail-html"
                      className="col-sm-3 col-form-label"
                    >
                      รายละเอียด
                    </label>
                    <div className="col-sm-8">
                      <Editor
                        editorStyle={{
                          border: "1px solid #F1F1F1",
                          borderRadius: 3,
                          height: 400,
                          lineHeight: 1,
                        }}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        editorState={detail}
                        onEditorStateChange={onEditorStateChange}
                        toolbar={{
                          options: [
                            "inline",
                            //   "blockType",
                            "fontSize",
                            //   "fontFamily",
                            "image",
                            "colorPicker",
                            "textAlign",
                            "history",
                          ],
                          inline: {
                            options: ["bold", "italic", "underline"],
                          },

                          image: {
                            uploadCallback: uploadImageCallBack,
                            previewImage: true,
                            alt: { present: true, mandatory: false },
                            inputAccept: "image/jpeg,image/jpg",
                          },
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      ส่งข่าวถึง
                    </label>
                    <div className="col-sm-5">
                      <Select
                        value={send_to}
                        onChange={async (e) => {
                          await setsend_to(e);
                          trigger("send_to");
                        }}
                        options={option_send}
                        placeholder={"เลือก.."}
                        closeMenuOnSelect={false}
                        isMulti
                        isDisabled={status === 0 || status === 1 ? true : false}
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            borderColor: errors.send_to ? "red" : "#cdd4e0",
                            "&:focus": {
                              borderColor: errors.send_to ? "red" : "#cdd4e0",
                            },
                          }),
                        }}
                        ref={(e) => {
                          register(
                            { name: "send_to" },
                            {
                              validate: (value) => {
                                return send_to.length === 0 ? false : true;
                              },
                            }
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">วันที่ส่ง</label>
                    <div className="col-sm-5">
                      <div className="row">
                        <div className="col-7">
                          <DayPickerInput
                            value={effective_day}
                            onDayChange={async (e) => {
                              await seteffective_day(e);
                            }}
                            placeholder={"วัน/เดือน/ปี"}
                            style={{ width: "100%", height: "100%" }}
                            inputProps={{
                              style: {
                                width: "100%",
                                padding: 5,
                                border: "1px solid #cdd4e0",
                                borderRadius: 5,
                                height: "100%",
                              },
                              disabled:
                                status === 0 || status === 1 ? true : false,
                            }}
                            overlayComponent={CustomOverlay}
                            format="L"
                            formatDate={formatDate}
                            parseDate={parseDate}
                            dayPickerProps={{
                              locale: "th",
                              localeUtils: MomentLocaleUtils,
                              disabledDays: { before: new Date() },
                            }}
                          />
                        </div>
                        <div className="col-5">
                          <input
                            className="form-control"
                            type="time"
                            min="00:00"
                            max="60:00"
                            value={effective_time}
                            onChange={(e) => {
                              //   let ef = moment(
                              //     effective_day,
                              //     "DD/MM/YYYY"
                              //   ).format("YYYY-MM-DD");

                              //   let is_af = moment(
                              //     `${ef} ${e.target.value}`,
                              //     "YYYY-MM-DD h:mm"
                              //   );

                              //   if (is_af.isAfter()) {
                              //     seteffective_time(e.target.value);
                              //   }
                              console.log(e.target.value);
                              seteffective_time(e.target.value);
                            }}
                            disabled={
                              status === 0 || status === 1 ? true : false
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="note-html"
                      className="col-sm-3 col-form-label"
                    >
                      หมายเหตุ
                    </label>
                    <div className="col-sm-5">
                      <input
                        name="note"
                        type="text"
                        className="form-control"
                        value={note}
                        onChange={(e) => setnote(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <button
                      type="submit"
                      className="btn btn-primary mr-2 ml-auto "
                    >
                      บันทึก
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
