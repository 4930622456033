import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { compose, withProps } from "recompose";
import { google_api_key } from "../../config";

const MyMapComponent = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${google_api_key}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `570px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  // console.log("re-render");
  return (
    <GoogleMap defaultCenter={props.point} zoom={15} center={props.point}>
      <Marker
        position={props.point}
        draggable={true}
        onDragEnd={(e) => {
          props.setpoint({ lat: e.latLng.lat(), lng: e.latLng.lng() });
        }}
      />
    </GoogleMap>
  );
});

export const RestaurantEditLocation = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    sendToParent() {
      props.callbackLocation(point);
    },
  }));
  // const [point, setpoint] = useState({ lat: 15.8633445933598, lng: 100.99709002648925 });
  const [point, setpoint] = useState({ lat: 13.674351, lng: 100.517556 });

  useEffect(() => {
    // console.log(props);
    async function getPoint() {
      await setpoint({
        lat: props.parentData.location.latitude,
        lng: props.parentData.location.longitude,
      });
    }

    if (Object.keys(props.parentData).length > 0) {
      getPoint();
    }
  }, [props.parentData]);

  return (
    <div>
      <div style={{ height: 570, width: "100%" }}>
        <MyMapComponent point={point} setpoint={setpoint} />
      </div>
      <div className="forms-sample row mt-4">
        <div className="form-group col-6">
          <label htmlFor="exampleInputEmail1">พิกัดละติจูด ( Latitude )</label>
          <input
            type="text"
            className="form-control"
            id="laText"
            value={point.lat}
            placeholder="พิกัดละติจูด"
            name="La"
            onChange={(e) => {
              setpoint((prev) => {
                return { ...prev, lat: parseFloat(e.target.value) };
              });
            }}
          />
        </div>
        <div className="form-group col-6">
          <label htmlFor="exampleInputPassword1">
            พิกัดลองจิจูด ( Longitude )
          </label>
          <input
            type="text"
            className="form-control"
            id="loText"
            value={point.lng}
            placeholder="พิกัดลองจิจูด"
            name="Lo"
            onChange={(e) => {
              setpoint((prev) => {
                return { ...prev, lng: parseFloat(e.target.value) };
              });
            }}
            // readOnly
          />
        </div>

        {/* <div className="form-group col-4">
          <label htmlFor="rangeInput">กำหนดระยะในการให้บริการ</label>
          <input
            id="range"
            type="range"
            name="rangeInput"
            min="10"
            max="100"
            style={{ width: "100%" }}
            step="1"
            value={radius}
            onChange={(e) => {
              setRadius(parseInt(e.target.value));

              console.log(radius);
            }}
          />
          <p id="result">{radius} เมตร</p>
        </div> */}
      </div>

      <div className="row">
        <div className="col-10"></div>
        <div className="col">
          <div className="row">
            <a
              tabIndex="0"
              className="btn btn-primary  mr-2  mt-2 "
              onClick={() => {
                props.callbackLocation(point);
                props.submit(props.data);
              }}
            >
              บันทึกข้อมูล
            </a>
          </div>
        </div>
      </div>
    </div>
  );
});
