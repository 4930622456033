import React, { useEffect, useState, useRef } from "react";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Select from "react-select";
import swal from "sweetalert";
import Axios from "axios";
import { api, header_token } from "../../config";

const option_order = [{ value: "cancel", label: "ยกเลิก" }];

export const OrderPreOrder = (props) => {
  const checkAllRef = useRef(null);

  const [default_data, setdefault_data] = useState([]);
  const [data, setdata] = useState([]);
  const [current_page, setcurrent_page] = useState(1);
  const [page_count, setpage_count] = useState(0);
  const [limit] = useState(10);

  const [id, setid] = useState("");
  const [option_area, setoption_area] = useState([]);
  const [filter_area, setfilter_area] = useState("");
  const [filter_order, setfilter_order] = useState("");
  const [filter_name, setfilter_name] = useState("");

  const cancelOrder = async (order_id) => {
    try {
      let res = await Axios.get(
        `${api}/admin/order/${order_id}/cancel`,
        header_token
      );
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  useEffect(() => {
    if (props.data) {
      setdata(props.data);
      setdefault_data(props.data);
      setoption_area(props.optionArea);
      let cal_page_count = Math.ceil(props.data.length / limit);
      setpage_count(cal_page_count);
    }
  }, [props]);

  useEffect(() => {
    if (default_data.length > 0) {
      let tmp = default_data;

      if (filter_area.value) {
        tmp = default_data.filter(
          (v) => v.restaurant.area.id === filter_area.value
        );
      }

      if (id) {
        tmp = tmp.filter((v) => v.order_no.includes(id));
      }

      if (filter_name) {
        tmp = tmp.filter((v) =>
          v.restaurant.name_th.toLowerCase().includes(filter_name.toLowerCase())
        );
      }

      setdata(tmp);
      let cal_page_count = Math.ceil(tmp.length / limit);
      setpage_count(cal_page_count);
    }
  }, [filter_area, id, filter_name]);

  return (
    <div>
      <div className="row">
        <div className="col-12 mb-3">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <h4 className="mb-4 mt-2">ทั้งหมด {data.length} รายการ</h4>
                </div>

                <div className="form-row align-items-center mb-2 mr-2 ">
                  <div className=" mr-2" style={{ width: 200 }}>
                    <span className="tx-13 text-muted">ชื่อร้านอาหาร</span>
                    <input
                      type="text"
                      className="form-control- global_filter"
                      placeholder="ชื่อร้าน"
                      value={filter_name}
                      onChange={(e) => setfilter_name(e.target.value)}
                    />
                  </div>

                  <div className=" mr-2" style={{ width: 200 }}>
                    <span className="tx-13 text-muted">Order Id</span>
                    <input
                      type="text"
                      className="form-control- global_filter"
                      id="global_filter"
                      placeholder="ID"
                      value={id}
                      onChange={(e) => setid(e.target.value)}
                    />
                  </div>

                  <div className="mr-1" style={{ width: 150 }}>
                    <span className="tx-13 text-muted">พื้นที่</span>
                    <Select
                      value={filter_area}
                      onChange={async (e) => {
                        await setfilter_area(e);
                        let tmp = [];

                        e.value
                          ? (tmp = default_data.filter(
                              (v) => v.restaurant.area_id === e.value
                            ))
                          : (tmp = default_data);

                        setdata(tmp);

                        let cal_page_count = Math.ceil(tmp.length / limit);
                        setpage_count(cal_page_count);
                      }}
                      options={option_area}
                      placeholder={"พื้นที่.."}
                    />
                  </div>

                  <div className="col ml-1 mr-2">
                    <div className="row">
                      <div style={{ width: 120 }} className="">
                        <span className="tx-13 text-muted">ดำเนินการ</span>
                        <Select
                          // styles={{border: '1px solid #cdd4e0'}}
                          value={filter_order}
                          onChange={async (e) => {
                            setfilter_order(e);
                          }}
                          options={option_order}
                          placeholder={"เลือก..."}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ width: 120 }}>
                    <button
                      data-toggle="modal"
                      data-target={`${
                        filter_order.value === "assign" ? ".assign-order" : ""
                      }`}
                      className="btn btn-primary mr-2 "
                      style={{ height: 42, marginTop: 20 }}
                      onClick={async () => {
                        if (
                          !filter_order.value ||
                          data.filter((e) => e.checked === true).length === 0
                        ) {
                          alert("เลือกตัวดำเนินการ");
                          return;
                        }

                        if (filter_order.value === "cancel") {
                          swal({
                            title: `ต้องการยกเลิกออเดอร์ที่เลือกหรือไม่ ?`,
                            confirmButtonText: "ใช่",
                            cancelButtonText: "ไม่",
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                          }).then(async (willDelete) => {
                            if (willDelete) {
                              let sending = [];
                              data.forEach((value) => {
                                if (value.checked === true) {
                                  sending.push(cancelOrder(value.id));
                                }
                              });

                              let is_success = true;
                              await Promise.all(sending).then((res) => {
                                console.log(res);
                                if (
                                  res.filter((e) => e === true).length === 0 ||
                                  res.length === 0
                                )
                                  is_success = false;
                              });

                              if (is_success) {
                                swal("ยกเลิกเสร็จสิ้น !!", {
                                  icon: "success",
                                });
                              } else {
                                swal("เกิดข้อผิดพลาด !!", {
                                  icon: "error",
                                });
                              }
                            }
                          });
                        }
                      }}
                    >
                      ดำเนินการ
                    </button>
                  </div>
                </div>
              </div>

              <div className="sticky-div">
                <table id="dataTable" className="table sticky-table">
                  <thead>
                    <tr>
                      <th style={{ width: 30 }} className="sticky-head">
                        <input
                          type="checkbox"
                          className="selectAll "
                          name="selectAll"
                          defaultChecked={false}
                          ref={checkAllRef}
                          onClick={(e) => {
                            let tmp = [...data];
                            let _tmp = [];
                            tmp
                              .slice(
                                (current_page - 1) * limit,
                                current_page * limit
                              )
                              .forEach((value, index) => {
                                value.checked = checkAllRef.current.checked;
                                _tmp.push(value);
                              });

                            setdata(tmp);
                          }}
                        />
                      </th>
                      <th style={{ width: 150 }} className="sticky-head">
                        Order No.
                      </th>
                      <th style={{ width: 120 }}>เวลา</th>
                      <th style={{ width: 150 }}>การจัดส่ง</th>
                      <th style={{ width: 300 }}>ร้านอาหาร</th>
                      <th style={{ width: 300 }}>รายการอาหาร</th>
                      <th style={{ width: 150 }}>ผู้รับ</th>
                      <th style={{ width: 150 }}>ผู้ส่ง</th>
                      <th style={{ width: 80 }}>จำนวน</th>
                      <th style={{ width: 180 }}>ค่าอาหาร</th>
                      <th style={{ width: 180 }}>ค่าจัดส่ง</th>
                      <th style={{ width: 180 }}>โปรโมชั่น</th>
                      <th style={{ width: 180 }}>รวม</th>
                      <th style={{ width: 120 }}>ระยะทาง</th>
                      <th style={{ width: 150 }}>สถานะ</th>
                      <th style={{ width: 120 }}>ชำระ</th>
                      <th style={{ width: 150 }}>รายละเอียด</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data
                      .slice((current_page - 1) * limit, current_page * limit)
                      .map((value, index) => {
                        if (value.order_no.includes(id)) {
                          return (
                            <tr
                              key={index}
                              style={{
                                backgroundColor: value.checked
                                  ? "#e8eaed "
                                  : "",
                              }}
                            >
                              <td>
                                <input
                                  type="checkbox"
                                  checked={value.checked || ""}
                                  onChange={(e) => {
                                    let tmp = [...data];
                                    data.forEach((vl, idx) => {
                                      if (vl.id === value.id) {
                                        tmp[idx].checked = !tmp[idx].checked;
                                        setdata(tmp);
                                      }
                                    });
                                  }}
                                />
                              </td>
                              <td
                                className="sticky-col"
                                style={{
                                  backgroundColor: value.checked
                                    ? "#e8eaed "
                                    : "",
                                }}
                              >
                                <a
                                  tabIndex={0}
                                  onClick={() =>
                                    props.pushToLink(
                                      `/order/detail/${value.id}`
                                    )
                                  }
                                >
                                  {value.order_no}
                                </a>
                              </td>
                              <td>
                                <i className="fa fa-clock-o mr-1"></i>
                                {moment(value.created_at).format("LT")}
                              </td>
                              <td>
                                {value.type === 0 ? (
                                  <span className="badge badge-outline-warning badge-pill">
                                    {moment(value.due_date).format(
                                      "DD/MM/YYYY"
                                    )}{" "}
                                    {moment(value.due_date).format("LT")}
                                  </span>
                                ) : (
                                  <span className="badge badge-outline-success badge-pill">
                                    สังทันที
                                  </span>
                                )}
                              </td>
                              <td>
                                <a
                                  tabIndex={0}
                                  onClick={() =>
                                    props.pushToLink(
                                      `/restaurant/detail/${value.restaurant.id}/history`
                                    )
                                  }
                                >
                                  {value.restaurant.name_th}
                                  <br />
                                  {value.restaurant.phone_numbers}
                                </a>
                              </td>
                              <td>
                                <a
                                  tabIndex={0}
                                  onClick={() =>
                                    props.pushToLink(
                                      `/order/detail/${value.id}`
                                    )
                                  }
                                >
                                  {value.menu
                                    ? value.menu.map((val, idx) => {
                                        return (
                                          <div key={idx}>
                                            <span>
                                              {val.name_th}(x{val.quantity})
                                            </span>
                                          </div>
                                        );
                                      })
                                    : null}
                                </a>
                              </td>
                              <td>
                                {/* <Link to="user_profile"> */}
                                {value.user.full_name}
                                <br />
                                {value.user.phone_number}
                                {/* </Link> */}
                              </td>
                              <td>
                                <a
                                  tabIndex={0}
                                  onClick={() =>
                                    props.pushToLink(
                                      `/rider/history/${
                                        value.rider ? value.rider.id : ""
                                      }`
                                    )
                                  }
                                >
                                  {value.rider ? (
                                    <div>
                                      {value.rider.full_name}
                                      <br />
                                      {value.rider.phone_number}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </a>
                              </td>
                              <td>{value.menu ? value.menu.length : null}</td>
                              <td>{value.food_cost.toLocaleString("de")}</td>
                              <td>{value.shipping_cost.toLocaleString("de")}</td>
                              <td>
                                {value.promotion_discount > 0 ? (
                                  <span>
                                    {value.promotion_code
                                      ? value.promotion_code
                                      : ""}{" "}
                                    {value.promotion_discount
                                      ? `(${value.promotion_discount.toLocaleString("de")})`
                                      : "0"}
                                  </span>
                                ) : null}
                              </td>{" "}
                              <td>{value.total.toLocaleString("de")}</td>
                              <td>~ {value.distance}km</td>
                              <td>
                                {(() => {
                                  if (value.type === 0)
                                    return "ออเดอร์ล่วงหน้า";

                                  switch (value.status) {
                                    case 0:
                                      return "ยกเลิก";
                                    case 1:
                                      return "ออเดอร์ใหม่";
                                    case 4:
                                      return "กำลังจัดส่ง";
                                    case 5:
                                      return "ส่งสำเร็จ";
                                    case 6:
                                      return "ส่งไม่สำเร็จ";

                                    default:
                                      break;
                                  }
                                })()}
                              </td>
                              <td>{value.payment_method}</td>
                              <td>
                                <a
                                  tabIndex={0}
                                  onClick={() =>
                                    props.pushToLink(
                                      `/order/detail/${value.id}`
                                    )
                                  }
                                  className="btn btn-outline-primary btn-sm"
                                >
                                  รายละเอียด
                                </a>
                              </td>
                            </tr>
                          );
                        }
                      })}
                  </tbody>
                </table>
              </div>
              <div className="mt-2" style={{ float: "right" }}>
                <ReactPaginate
                  previousLabel={"← Previous"}
                  nextLabel={"Next →"}
                  breakLabel={<span className="gap">...</span>}
                  pageCount={page_count}
                  onPageChange={(e) => setcurrent_page(e.selected + 1)}
                  forcePage={current_page - 1}
                  containerClassName={"pagination"}
                  previousLinkClassName={"previous_page"}
                  nextLinkClassName={"next_page"}
                  disabledClassName={"disabled"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
