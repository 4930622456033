import React, { useEffect, useRef } from "react";
import ReactExport from "react-export-excel";
import moment from "moment";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const ExportSaleBillServiceData = ({ data, trigger, settrigger }) => {
  const exportButton = useRef();

  useEffect(() => {
    if (trigger) {
      exportButton.current.click();
      settrigger(false);
    }
  }, [trigger]);

  return (
    <ExcelFile
      filename={`Sale_Bill_Service_Export_${moment(new Date()).format(
        "DD-MM-YYYY"
      )}`}
      element={
        <button
          type="submit"
          className="btn btn-primary mr-2"
          style={{ marginTop: 23, display: "none" }}
          ref={exportButton}
        >
          Download Data
        </button>
      }
    >
      <ExcelSheet data={data} name="Sale_Bill_Service">
        <ExcelColumn label="Order No" value="order_no" />
        <ExcelColumn label="เวลา" value="order_time" />
        <ExcelColumn label="บริการ" value="service_name" />
        <ExcelColumn label="บริการเสริม" value="service_addons" />
        <ExcelColumn label="ค่าบริการ" value="service_cost" />
        <ExcelColumn
          label="ค่าคอมมิชชั่นค่าบริการ"
          value="service_commission"
        />
        <ExcelColumn label="สถานะ" value="status" />
      </ExcelSheet>
    </ExcelFile>
  );
};
