import React, { useState, useEffect } from "react";

import { api, header_token } from "../../config";
import swal from "sweetalert";
import Axios from "axios";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

export const SettingPayment = () => {
  const { register, handleSubmit } = useForm();
  const history = useHistory();

  const [delivery_distance, setdelivery_distance] = useState(0);
  const [error_value, seterror_value] = useState(0);
  const [increase_per_kilometer, setincrease_per_kilometer] = useState(0);
  const [percentage_vat, setpercentage_vat] = useState(0);

  const [minimum_price, setminimum_price] = useState(0);
  const [percentage_promotion_price_gp, setpercentage_promotion_price_gp] =
    useState(0);
  const [percentage_standard_price_gp, setpercentage_standard_price_gp] =
    useState(0);
  const [
    percentage_standard_price_rider_charge,
    setpercentage_standard_price_rider_charge,
  ] = useState(0);
  const [receiving_distance, setreceiving_distance] = useState(0);
  const [start_price, setstart_price] = useState(0);

  const [cost_distance, setcost_distance] = useState([]);

  const getDataServiceCharge = async () => {
    try {
      let { data } = await Axios.get(`${api}/service-charge`, header_token);
      // console.log("ServiceCharge ", data);
      setdelivery_distance(data.delivery_distance);
      setreceiving_distance(data.receiving_distance);
      seterror_value(data.error_value);
      setincrease_per_kilometer(data.increase_per_kilometer);
      setminimum_price(data.minimum_price);
      setpercentage_promotion_price_gp(data.percentage_promotion_price_gp);
      setpercentage_standard_price_rider_charge(
        data.percentage_standard_price_rider_charge
      );
      setpercentage_standard_price_gp(data.percentage_standard_price_gp);
      setstart_price(data.start_price);
      setpercentage_vat(data.percentage_vat);
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const getDataCostDistance = async () => {
    try {
      let { data } = await Axios.get(`${api}/cost-distance`, header_token);
      // console.log("from get", data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({
          id: value.id,
          lte_distance: value.lte_distance,
          percentage_price_rider_charge: value.percentage_price_rider_charge,
          price: value.price,
          edit: false,
          new: false,
        });
      });

      setcost_distance(tmp);
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const editCostDistance = async (cost_id, send_data) => {
    try {
      let res = await Axios.put(
        `${api}/cost-distance/${cost_id}`,
        send_data,
        header_token
      );
      // console.log(res);
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      getDataCostDistance();

      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const addCostDistance = async (send_data) => {
    try {
      let res = await Axios.post(
        `${api}/cost-distance`,
        send_data,
        header_token
      );
      // console.log(res);
      if (res.status === 201) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      getDataCostDistance();

      // console.log(error.response);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const deleteCostDistance = async (cost_id) => {
    try {
      let res = await Axios.delete(
        `${api}/cost-distance/${cost_id}`,
        header_token
      );

      // console.log(res);
      if (res.status === 200) {
        getDataCostDistance();

        return true;
      } else {
        return false;
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const onSubmit = async (data) => {
    // // console.log("submit", data);

    let send_data = {
      delivery_distance: delivery_distance,
      error_value: parseFloat(error_value),
      increase_per_kilometer: increase_per_kilometer,
      percentage_vat: parseFloat(percentage_vat),
      minimum_price: minimum_price,
      percentage_promotion_price_gp: percentage_promotion_price_gp,
      percentage_standard_price_gp: percentage_standard_price_gp,
      percentage_standard_price_rider_charge:
        percentage_standard_price_rider_charge,
      receiving_distance: receiving_distance,
      start_price: start_price,
    };
    // console.log("send_data", send_data);
    try {
      let res = await Axios.put(
        `${api}/service-charge`,
        send_data,
        header_token
      );
      // console.log(res);
      if (res.status === 201 || res.status === 200) {
        // // console.log("okkk");
        swal("แก้ไขเสร็จสิ้น !!", {
          icon: "success",
        });
        history.push(`/setting/payment`);
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };
  useEffect(() => {
    getDataServiceCharge();
    getDataCostDistance();
  }, []);

  return (
    <div>
      <div className="row mb-4">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mb-4">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="form-group row">
                          <label
                            htmlFor=""
                            className="col-sm-3 col-form-label "
                          >
                            ระยะทางจัดส่งที่ครอบคลุม(km)
                          </label>
                          <div className="col-sm-4">
                            <input
                              type="text"
                              className="form-control-"
                              placeholder="20"
                              style={{ width: 80 }}
                              name="delivery_distance"
                              ref={register({ required: true })}
                              value={delivery_distance}
                              onChange={(e) =>
                                setdelivery_distance(
                                  parseFloat(
                                    e.target.value
                                      ? e.target.value.replace(/[A-Za-z,]/g, "")
                                      : 0
                                  )
                                )
                              }
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <label
                            htmlFor=""
                            className="col-sm-3 col-form-label "
                          >
                            ระยะทางรับออร์เดอร์ที่ครอบคลุม(km)
                          </label>
                          <div className="col-sm-4">
                            <input
                              type="text"
                              className="form-control-"
                              placeholder="20"
                              style={{ width: 80 }}
                              name="receiving_distance"
                              ref={register({ required: true })}
                              value={receiving_distance}
                              onChange={(e) =>
                                setreceiving_distance(
                                  parseFloat(
                                    e.target.value
                                      ? e.target.value.replace(/[A-Za-z,]/g, "")
                                      : 0
                                  )
                                )
                              }
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <label htmlFor="" className="col-sm-3 col-form-label">
                            ค่าความคลาดเดลื่อน(+km)
                          </label>
                          <div className="col-sm-4">
                            <input
                              type="text"
                              className="form-control-"
                              placeholder="0.5"
                              style={{ width: 80 }}
                              name="error_value"
                              ref={register({ required: true })}
                              value={error_value}
                              onChange={(e) => {
                                seterror_value(
                                  e.target.value
                                    ? e.target.value.replace(/[A-Za-z,]/g, "")
                                    : 0
                                );
                              }}
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <label htmlFor="" className="col-sm-3 col-form-label">
                            ภาษีมูลค่าเพิ่ม (%)
                          </label>
                          <div className="col-sm-4">
                            <input
                              type="text"
                              className="form-control-"
                              placeholder="0.5"
                              style={{ width: 80 }}
                              name="percentage_vat"
                              ref={register({ required: true })}
                              value={percentage_vat}
                              onChange={(e) => {
                                setpercentage_vat(
                                  e.target.value
                                    ? e.target.value.replace(/[A-Za-z,]/g, "")
                                    : 0
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h4>ราคามาตรฐาน</h4>
                      </div>
                      <div className="card-body">
                        <div className="form-group row">
                          <div className="col-3">
                            <span className="">
                              ค่าบริการที่คิดจากร้านอาหาร GP(%)
                            </span>
                          </div>
                          <div className="col-sm-4">
                            <input
                              type="text"
                              className="form-control-"
                              placeholder="10"
                              style={{ width: 80 }}
                              name="percentage_standard_price_gp"
                              ref={register({ required: true })}
                              value={percentage_standard_price_gp}
                              onChange={(e) =>
                                setpercentage_standard_price_gp(
                                  parseFloat(
                                    e.target.value
                                      ? e.target.value.replace(/[A-Za-z,]/g, "")
                                      : 0
                                  )
                                )
                              }
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <div className="col-3">
                            <span className="">
                              ค่าบริการที่คิดจากพนักงานส่ง(%)
                            </span>
                          </div>
                          <div className="col-sm-4">
                            <input
                              type="text"
                              className="form-control-"
                              placeholder="10"
                              style={{ width: 80 }}
                              name="percentage_standard_price_rider_charge"
                              ref={register({ required: true })}
                              value={percentage_standard_price_rider_charge}
                              onChange={(e) =>
                                setpercentage_standard_price_rider_charge(
                                  parseFloat(
                                    e.target.value
                                      ? e.target.value.replace(/[A-Za-z,]/g, "")
                                      : 0
                                  )
                                )
                              }
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <div className="col-3">
                            <span className="">ราคาเริ่มต้น(บาท)</span>
                          </div>
                          <div className="col-sm-4">
                            <input
                              type="text"
                              className="form-control-"
                              placeholder="25"
                              style={{ width: 80 }}
                              name="start_price"
                              ref={register({ required: true })}
                              value={start_price}
                              onChange={(e) =>
                                setstart_price(
                                  parseFloat(
                                    e.target.value
                                      ? e.target.value.replace(/[A-Za-z,]/g, "")
                                      : 0
                                  )
                                )
                              }
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <div className="col-3">
                            <span className="">บวกเพิ่มกิโลเมตรละ(บาท)</span>
                          </div>
                          <div className="col-sm-4">
                            <input
                              type="text"
                              className="form-control-"
                              placeholder="9"
                              style={{ width: 80 }}
                              name="increase_per_kilometer"
                              ref={register({ required: true })}
                              value={increase_per_kilometer}
                              onChange={(e) =>
                                setincrease_per_kilometer(
                                  parseFloat(
                                    e.target.value
                                      ? e.target.value.replace(/[A-Za-z,]/g, "")
                                      : 0
                                  )
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h4>ราคาร่วมโปรโมชั่น</h4>
                      </div>
                      <div className="card-body">
                        <div className="form-group row">
                          <div className="col-3">
                            <span className="">
                              ค่าบริการที่คิดจากร้านอาหารGP(%)
                            </span>
                          </div>
                          <div className="col-sm-4">
                            <input
                              type="text"
                              className="form-control-"
                              id=""
                              placeholder="30"
                              style={{ width: 80 }}
                              name="percentage_promotion_price_gp"
                              ref={register({ required: true })}
                              value={percentage_promotion_price_gp}
                              onChange={(e) =>
                                setpercentage_promotion_price_gp(
                                  parseFloat(
                                    e.target.value
                                      ? e.target.value.replace(/[A-Za-z,]/g, "")
                                      : 0
                                  )
                                )
                              }
                            />
                          </div>
                        </div>

                        {/* <div className="form-group row">
                          <div className="col-3">
                            <span className="">
                              ค่าบริการที่คิดจากพนักงานส่ง(%)
                            </span>
                          </div>
                          <div className="col-sm-4">
                            <input
                              type="text"
                              className="form-control-"
                              placeholder="10"
                              style={{ width: 80 }}
                              name="percentage_promotion_price_gp"
                              ref={register({ required: true })}
                              value={percentage_promotion_price_gp}
                              onChange={(e) =>
                                setpercentage_promotion_price_gp(parseFloat(e.target.value.replace(/[A-Za-z]/g,"")))
                              }
                            />
                          </div>
                        </div> */}

                        <div className="form-group row">
                          <div className="col-3">
                            <span className="">ราคาขั้นต่ำต่อออเดอร์(บาท)</span>
                          </div>
                          <div className="col-sm-4">
                            <input
                              type="text"
                              className="form-control-"
                              placeholder="50"
                              style={{ width: 80 }}
                              name="minimum_price"
                              ref={register({ required: true })}
                              value={minimum_price}
                              onChange={(e) =>
                                setminimum_price(
                                  parseFloat(
                                    e.target.value
                                      ? e.target.value.replace(/[A-Za-z,]/g, "")
                                      : 0
                                  )
                                )
                              }
                            />
                          </div>
                        </div>

                        <div className="table-responsive">
                          <p className="mb-2">ค่าจัดส่งคิดตามระยะทาง</p>

                          <div className="content">
                            <table id="example" className="table ">
                              <thead>
                                <tr>
                                  <th style={{ width: "15%" }}>
                                    น้อยกว่า (km)
                                  </th>

                                  <th style={{ width: "40%" }}>
                                    ค่าบริการหักจากพนักงานส่ง(%)
                                  </th>
                                  <th style={{ width: "15%" }}>ราคา(บาท)</th>
                                  <th width="80">Edit</th>
                                  <th width="80">Delete</th>
                                </tr>
                              </thead>
                              <tbody>
                                {cost_distance.map((value, index) => {
                                  // // console.log("in loop", value);
                                  return (
                                    <tr key={value.id}>
                                      <td className="name">
                                        {value.edit === true ? (
                                          <input
                                            type="number"
                                            className="form-control"
                                            value={value.lte_distance}
                                            onChange={(e) => {
                                              let tmp = [...cost_distance];
                                              tmp[index].lte_distance =
                                                parseFloat(
                                                  e.target.value
                                                    ? e.target.value.replace(
                                                        /[A-Za-z]/g,
                                                        ""
                                                      )
                                                    : 0
                                                );

                                              setcost_distance(tmp);
                                            }}
                                          />
                                        ) : (
                                          value.lte_distance
                                        )}
                                      </td>

                                      <td className="percen">
                                        {value.edit === true ? (
                                          <input
                                            type="number"
                                            className="form-control"
                                            value={
                                              value.percentage_price_rider_charge
                                            }
                                            onChange={(e) => {
                                              let tmp = [...cost_distance];
                                              tmp[
                                                index
                                              ].percentage_price_rider_charge =
                                                parseFloat(
                                                  e.target.value
                                                    ? e.target.value.replace(
                                                        /[A-Za-z]/g,
                                                        ""
                                                      )
                                                    : 0
                                                );
                                              setcost_distance(tmp);
                                            }}
                                          />
                                        ) : (
                                          value.percentage_price_rider_charge
                                        )}
                                      </td>

                                      <td className="price">
                                        {value.edit === true ? (
                                          <input
                                            type="number"
                                            className="form-control"
                                            value={value.price}
                                            onChange={(e) => {
                                              let tmp = [...cost_distance];
                                              tmp[index].price = parseFloat(
                                                e.target.value
                                                  ? e.target.value.replace(
                                                      /[A-Za-z]/g,
                                                      ""
                                                    )
                                                  : 0
                                              );
                                              setcost_distance(tmp);
                                            }}
                                          />
                                        ) : (
                                          value.price
                                        )}
                                      </td>

                                      <td className="edit">
                                        {value.edit === false ? (
                                          <i
                                            style={{ cursor: "pointer" }}
                                            className="fa tx-20 mt-1 ml-1 text-muted fa-edit"
                                            onClick={() => {
                                              let tmp = [...cost_distance];
                                              tmp[index].edit =
                                                !tmp[index].edit;
                                              setcost_distance(tmp);
                                            }}
                                          ></i>
                                        ) : (
                                          <i
                                            style={{ cursor: "pointer" }}
                                            className="fa tx-20 mt-1 ml-1 text-muted fa-save"
                                            onClick={async () => {
                                              let tmp = [...cost_distance];

                                              if (tmp[index].new === true) {
                                                // console.log("add");
                                                let send_data = {
                                                  lte_distance:
                                                    value.lte_distance,
                                                  percentage_price_rider_charge:
                                                    value.percentage_price_rider_charge,
                                                  price: value.price,
                                                };
                                                // // console.log(tmp[index]);
                                                await addCostDistance(
                                                  send_data
                                                );
                                              } else {
                                                let send_data = {
                                                  lte_distance:
                                                    tmp[index].lte_distance,
                                                  percentage_price_rider_charge:
                                                    tmp[index]
                                                      .percentage_price_rider_charge,
                                                  price: tmp[index].price,
                                                };
                                                // console.log("edit", send_data);

                                                await editCostDistance(
                                                  value.id,
                                                  send_data
                                                );
                                              }

                                              getDataCostDistance();
                                              tmp[index].edit =
                                                !tmp[index].edit;
                                              tmp[index].new = false;

                                              setcost_distance(tmp);
                                            }}
                                          ></i>
                                        )}
                                      </td>
                                      <td className="trash">
                                        <i
                                          style={{ cursor: "pointer" }}
                                          className="fa fa-trash-o tx-20 mt-1 ml-3 text-muted"
                                          onClick={() => {
                                            if (value.edit === false) {
                                              swal({
                                                title: `ต้องการลบหรือไม่ ?`,
                                                // text:
                                                //   "Once deleted, you will not be able to recover this imaginary file!",
                                                confirmButtonText: "ลบ",
                                                cancelButtonText: "ยกเลิก",
                                                icon: "warning",
                                                buttons: true,
                                                dangerMode: true,
                                              }).then(async (willDelete) => {
                                                if (willDelete) {
                                                  let is_delete =
                                                    deleteCostDistance(
                                                      value.id
                                                    );
                                                  if (is_delete) {
                                                    swal("ลบเสร็จสิ้น !!", {
                                                      icon: "success",
                                                    });
                                                  } else {
                                                    swal("เกิดข้อผิดพลาด !!", {
                                                      icon: "error",
                                                    });
                                                  }
                                                }
                                              });
                                            }
                                          }}
                                        ></i>
                                      </td>
                                    </tr>
                                  );
                                })}

                                <tr>
                                  <td
                                    className="new-row"
                                    colSpan="5"
                                    onClick={() => {
                                      let tmp = [...cost_distance];
                                      tmp.push({
                                        id: "",
                                        name_th: "",
                                        name_en: "",
                                        edit: true,
                                        new: true,
                                      });
                                      setcost_distance(tmp);
                                    }}
                                  >
                                    <span className="h3">+</span>{" "}
                                    <span className="h6">Add</span>{" "}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <button className="btn btn-primary mr-2 ml-auto">
                    บันทึก
                  </button>
                  <button className="btn btn-outline-primary mr-3">
                    ยกเลิก
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
