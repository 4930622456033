import React, { useState, useEffect } from "react";
import Select from "react-select";
import $ from "jquery";
import dropify from "dropify";
import { useForm, Controller } from "react-hook-form";
import Axios from "axios";
import swal from "sweetalert";

import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import "moment/locale/th";
import moment from "moment";

import {
  api,
  restaurant_status_option,
  img_url,
  header_token,
} from "../../config";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { useHistory, useParams } from "react-router-dom";

export const RiderEdit = () => {
  const { id } = useParams();
  const { register, handleSubmit, errors, control, trigger } = useForm();
  const history = useHistory();
  const [loading, setloading] = useState(false);

  const [rider_img, setrider_img] = useState({});
  const [driving_picture, setdriving_picture] = useState({
    preview: "",
    base64: "",
  });
  const [full_name, setfull_name] = useState("");
  const [user_id, setuser_id] = useState("");

  const [identification_number, setidentification_number] = useState("");
  const [identification_picture, setidentification_picture] = useState({
    preview: "",
    base64: "",
  });
  const [phone_number, setphone_number] = useState("");
  const [line_id, setline_id] = useState("");
  const [email, setemail] = useState("");

  const [birth_date, setbirth_date] = useState("");
  const [address, setaddress] = useState("");
  const [zip_code, setzip_code] = useState("");
  const [sub_district_id, setsub_district_id] = useState("");
  const [district_id, setdistrict_id] = useState("");
  const [province_id, setprovince_id] = useState("");
  const [area_id, setarea_id] = useState("");
  const [employee_type_id, setemployee_type_id] = useState("");
  const [degree_id, setdegree_id] = useState("");
  const [team_id, setteam_id] = useState("");
  const [service_type, setservice_type] = useState("");

  const [register_date, setregister_date] = useState("");
  const [credit, setcredit] = useState(0);
  const [point, setpoint] = useState(0);
  const [vehicle_type_id, setvehicle_type_id] = useState("");
  const [license_plate, setlicense_plate] = useState("");
  const [vehicle_color, setvehicle_color] = useState("");
  const [vehicle_make, setvehicle_make] = useState("");
  const [status, setstatus] = useState(0);

  const [option_sub_district, setoption_sub_district] = useState([]);
  const [option_district, setoption_district] = useState([]);
  const [option_province, setoption_province] = useState([]);
  const [option_area, setoption_area] = useState([]);
  const [option_employee_type, setoption_employee_type] = useState([]);
  const [option_degree, setoption_degree] = useState([]);
  const [option_team, setoption_team] = useState([]);
  const [option_vehicle_type, setoption_vehicle_type] = useState([]);
  const [option_service, setoption_service] = useState([]);

  const getOptionArea = async () => {
    try {
      let { data } = await Axios.get(`${api}/area`);
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      setoption_area(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  const getOptionEmployee = async () => {
    try {
      let { data } = await Axios.get(`${api}/employee-type`);
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      setoption_employee_type(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  const getOptionDegree = async () => {
    try {
      let { data } = await Axios.get(`${api}/degree`);
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      setoption_degree(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  const getOptionTeam = async () => {
    try {
      let { data } = await Axios.get(`${api}/teams`);
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      setoption_team(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  const getOptionVehicleType = async () => {
    try {
      let { data } = await Axios.get(`${api}/vehicle-type`);
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      setoption_vehicle_type(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  const getOptionSubDistrict = async (district_id) => {
    try {
      let { data } = await Axios.get(
        `${api}/sub-district/district/${district_id}`
      );
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({
          value: value.id,
          label: value.name_th,
          zip_code: value.zip_code,
        });
      });
      setoption_sub_district(tmp);
      // console.log(option_sub_district);
    } catch (error) {
      console.log(error);
    }
  };

  const getOptionDistrict = async (province_id) => {
    try {
      let { data } = await Axios.get(`${api}/district/province/${province_id}`);
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      setoption_district(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  const getOptionProvince = async () => {
    try {
      let { data } = await Axios.get(`${api}/province`);
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      setoption_province(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  const getOptionService = async () => {
    try {
      // let { data } = await Axios.post(`${api}/car-service/filter`, {
      //   status: [0, 1],
      // });
      let tmp = [];
      tmp.push({ value: "000000000000000000000000", label: "อาหาร" });
      // data.forEach((value) => {
      //   tmp.push({ value: value.id, label: value.name });
      // });
      setoption_service(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onSubmit = async (data) => {
    let birth_dateMoment = moment(birth_date);
    var birth_dateStr = birth_dateMoment.format("YYYY-MM-DD");

    let register_dateMoment = moment(register_date);
    var register_dateStr = register_dateMoment.format("YYYY-MM-DD");

    let send_data = {
      about: "",
      area_id: area_id ? area_id.map((e) => e.value) : [],
      birth_date: birth_dateStr,
      credit: credit,
      degree_id: degree_id.value,
      display_name: "",
      driving_license: "",
      driving_picture: driving_picture.base64
        ? driving_picture.base64
        : driving_picture.preview,
      email: email,
      employee_type_id: employee_type_id.value,
      full_name: full_name,
      gender_id: "",
      identification_number: identification_number,
      identification_picture: identification_picture.base64
        ? identification_picture.base64
        : identification_picture.preview,
      license_plate: license_plate,
      line_id: line_id,
      location: {
        address: address,
        district_id: district_id.value,
        latitude: 0,
        longitude: 0,
        province_id: province_id.value,
        sub_district_id: sub_district_id.value,
      },
      phone_number: phone_number,
      picture: rider_img.base64 ? rider_img.base64 : rider_img.preview,
      point: point,
      register_date: register_dateStr,
      status: parseInt(status.value),
      team_id: team_id.value,
      thumbnail: "",
      title_id: "",
      vehicle_color: vehicle_color,
      vehicle_make: vehicle_make,
      vehicle_type_id: vehicle_type_id.value,
    };

    // console.log("send data", send_data);
    setloading(true);

    try {
      let res = await Axios.put(
        `${api}/admin/user/profile/${id}`,
        send_data,
        header_token
      );

      let service_res = await Axios.put(
        `${api}/rider/${id}/service`,
        { service_id: service_type ? service_type.map((e) => e.value) : [] },
        header_token
      );

      // console.log("service_res", service_res);
      if (res.status === 200 && service_res.status === 200) {
        swal("แก้ไขเสร็จสิ้น !!", {
          icon: "success",
          timer: 1500,
        });
        setTimeout(() => {
          history.push(`/rider/history/${id}`);
        }, 1600);
      }
    } catch (error) {
      console.log(error);
      // var obj = JSON.parse(text, function (key, value) {
      let err = JSON.parse(error.response.data.replace("null", "").trim());
      swal(`${err.message}`, {
        icon: "error",
        button: false,
      });
    }
    setloading(false);
  };

  const getData = async () => {
    setloading(true);

    let { data } = await Axios.get(
      `${api}/admin/user/profile/${id}`,
      header_token
    );
    // console.log("getdata", data);

    setrider_img({
      preview: data.picture,
      base64: "",
    });
    setfull_name(data.full_name);
    setuser_id(data.user_id);
    setidentification_number(data.identification_number);
    setphone_number(data.phone_number);
    setline_id(data.line_id);
    setemail(data.email);

    setservice_type(
      data.services
        ? option_service.filter((e) => data.services.includes(e.value))
        : []
    );

    var _birth_date = new Date(data.birth_date);

    setbirth_date(_birth_date);
    setaddress(data.location.address);
    setprovince_id({
      value: data.location.province.id,
      label: data.location.province.name_th,
    });
    setdistrict_id({
      value: data.location.district.id,
      label: data.location.district.name_th,
    });
    setsub_district_id({
      value: data.location.sub_district.id,
      label: data.location.sub_district.name_th,
    });
    setzip_code(data.location.sub_district.zip_code);
    let area = [];
    if (data.area) {
      data.area.map((val) => {
        area.push({ value: val.id, label: val.name_th });
        return;
      });
    }

    setarea_id(area);
    setdegree_id(
      data.degree
        ? {
            value: data.degree.id,
            label: data.degree.name_th,
          }
        : ""
    );
    setemployee_type_id(
      data.employee_type
        ? {
            value: data.employee_type.id,
            label: data.employee_type.name_th,
          }
        : ""
    );
    setteam_id(
      data.team
        ? {
            value: data.team.id,
            label: data.team.name_th,
          }
        : ""
    );

    var _register_date = new Date(data.register_date);

    setregister_date(_register_date);
    setpoint(data.point);
    setcredit(data.credit);
    setvehicle_type_id(
      data.vehicle_type
        ? {
            value: data.vehicle_type.id,
            label: data.vehicle_type.name_th,
          }
        : ""
    );
    setlicense_plate(data.license_plate);
    setvehicle_make(data.vehicle_make);
    setvehicle_color(data.vehicle_color);

    setdriving_picture({
      preview: data.driving_picture,
      base64: "",
    });

    setidentification_picture({
      preview: data.identification_picture,
      base64: "",
    });

    let _status = restaurant_status_option.filter(
      (e) => parseInt(e.value) === parseInt(data.status)
    );
    // console.log(_status);
    setstatus(_status[0]);

    $(".dropify").dropify();

    var drEventRider = $("#rider_img").dropify();
    var drEventDriver = $("#driving_picture").dropify();
    var drEventIden = $("#identification_picture").dropify();

    drEventRider.on("dropify.afterClear", async function (event, element) {
      await setrider_img({
        preview: "",
        base64: "",
      });
      trigger("rider_img");
    });

    drEventDriver.on("dropify.afterClear", async function (event, element) {
      await setdriving_picture({
        preview: "",
        base64: "",
      });
      trigger("driving_picture");
    });

    drEventIden.on("dropify.afterClear", async function (event, element) {
      await setidentification_picture({
        preview: "",
        base64: "",
      });
      trigger("identification_picture");
    });

    setloading(false);
  };

  useEffect(() => {
    // getData();
    getOptionArea();
    getOptionProvince();
    // getOptionSubDistrict()
    getOptionDegree();
    getOptionEmployee();
    getOptionTeam();
    getOptionVehicleType();
    getOptionService();
  }, []);

  useEffect(() => {
    if (option_service.length > 0) getData();
  }, [option_service]);

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <LoadingMask loading={loading} text={"loading..."}>
              <form onSubmit={handleSubmit(onSubmit)}>
                {/* <button
                type="submit"
                className="btn btn-primary  mr-2  mt-2 "
                // onClick={() => {
                //   // validateForm();
                // }}
              >
                บันทึก
              </button> */}
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="name">รูปพนักงาน</label>
                  </div>
                  <div className="col-lg-5">
                    <div className="mb-2">
                      {errors.rider_img ? (
                        <div
                          class="alert alert-danger"
                          role="alert"
                          style={{ width: "100%", padding: 5 }}
                        >
                          กรุณาอัพโหลดไฟล์
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <input
                      type="file"
                      id="rider_img"
                      className="dropify"
                      data-max-file-size="3M"
                      accept=".png, .jpg, .jpeg"
                      data-default-file={`${img_url}${rider_img.preview}`}
                      onChange={async (e) => {
                        // console.log(e.target.files[0]);
                        if (e.target.files.length) {
                          setrider_img({
                            preview: URL.createObjectURL(e.target.files[0]),
                            base64: await toBase64(e.target.files[0]),
                          });
                          // trigger('rider_img')
                        }
                      }}
                      // ref={(e) => {
                      //   register(
                      //     { name: "rider_img" },
                      //     {
                      //       validate: (value) => {
                      //         return rider_img.base64 || rider_img.preview
                      //           ? true
                      //           : false;
                      //       },
                      //     }
                      //   );
                      // }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="name">รหัสพนักงาน</label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      type="text"
                      disabled
                      // value="6300357"
                      className="form-control"
                      id="inlineFormInputGroupUsername"
                      placeholder=""
                      value={user_id}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="full_name">ชื่อ-นามสกุล</label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      id="full_name"
                      className={`form-control ${
                        errors.full_name ? "error-input" : ""
                      }`}
                      name="full_name"
                      type="text"
                      ref={register({ required: true })}
                      value={full_name}
                      onChange={(e) => {
                        setfull_name(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="identification_number">
                      เลขที่บัตรประชาชน
                    </label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      id="identification_number"
                      className={`form-control ${
                        errors.identification_number ? "error-input" : ""
                      }`}
                      name="identification_number"
                      type="text"
                      ref={register({ required: true, maxLength: 13 })}
                      value={identification_number}
                      onChange={(e) => {
                        setidentification_number(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="phone_number">เบอร์โทรศัพท์</label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      id="phone_number"
                      className={`form-control ${
                        errors.phone_number ? "error-input" : ""
                      }`}
                      name="phone_number"
                      type="text"
                      ref={register({ required: true })}
                      value={phone_number}
                      onChange={(e) => {
                        setphone_number(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="line_id">ID Line</label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      id="line_id"
                      className={`form-control ${
                        errors.line_id ? "error-input" : ""
                      }`}
                      name="line_id"
                      type="text"
                      // ref={register({ required: true })}
                      value={line_id}
                      onChange={(e) => {
                        setline_id(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="email">อีเมล</label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      id="email"
                      className={`form-control ${
                        errors.email ? "error-input" : ""
                      }`}
                      name="email"
                      type="text"
                      ref={register({ required: true })}
                      value={email}
                      onChange={(e) => {
                        setemail(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="address">วัน/เดือน/ปีเกิด</label>
                  </div>
                  <div className="col-lg-5">
                    <div className="input-group input-daterange d-flex align-items-center">
                      <Controller
                        control={control}
                        name="birth_date"
                        render={({ onChange, value }) => (
                          <DayPickerInput
                            value={birth_date}
                            onDayChange={async (e) => {
                              await setbirth_date(e);
                              // trigger("birth_date");
                            }}
                            placeholder={"วัน/เดือน/ปี"}
                            style={{ width: "100%" }}
                            inputProps={{
                              style: {
                                width: "100%",
                                padding: 5,
                                borderColor: errors.birth_date
                                  ? "red"
                                  : "#cdd4e0",
                              },
                            }}
                            format="L"
                            formatDate={formatDate}
                            parseDate={parseDate}
                            dayPickerProps={{
                              locale: "th",
                              localeUtils: MomentLocaleUtils,
                            }}
                            // ref={(e) => {
                            //   register(
                            //     { name: "birth_date" },
                            //     {
                            //       validate: (value) => {
                            //         console.log(e.props);
                            //         return e.props.value === undefined ||
                            //           e.props.value === ""
                            //           ? false
                            //           : true;
                            //       },
                            //     }
                            //   );
                            // }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="address">ที่อยู่</label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      id="address"
                      className={`form-control ${
                        errors.address ? "error-input" : ""
                      }`}
                      name="address"
                      type="text"
                      // ref={register({ required: true })}
                      value={address}
                      onChange={(e) => {
                        setaddress(e.target.value);
                      }}
                    />
                  </div>
                </div>
                {/* <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="province_id">จังหวัด</label>
                  </div>
                  <div className="col-lg-5">
                    <Select
                      placeholder={"เลือกจังหวัด.."}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          borderColor: errors.province_id ? "red" : "#cdd4e0",
                          "&:hover": {
                            borderColor: errors.province_id ? "red" : "#cdd4e0",
                          },
                        }),
                      }}
                      value={province_id}
                      options={option_province}
                      onChange={async (e) => {
                        await setprovince_id(e);
                        // trigger("province_id");

                        getOptionDistrict(e.value);
                      }}
                      // ref={(e) => {
                      //   register(
                      //     { name: "province_id" },
                      //     {
                      //       validate: (value) => {
                      //         return e.props.value.value === undefined ||
                      //           e.props.value.value === ""
                      //           ? false
                      //           : true;
                      //       },
                      //     }
                      //   );
                      // }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="province">อำเภอ / เขต</label>
                  </div>
                  <div className="col-lg-5">
                    <Select
                      placeholder={"เลือกอำเภอ.."}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          borderColor: errors.district_id ? "red" : "#cdd4e0",
                          "&:hover": {
                            borderColor: errors.district_id ? "red" : "#cdd4e0",
                          },
                        }),
                      }}
                      value={district_id}
                      options={option_district}
                      onChange={async (e) => {
                        await setdistrict_id(e);
                        // trigger("district_id");

                        setsub_district_id("");

                        getOptionSubDistrict(e.value);
                      }}
                      // ref={(e) => {
                      //   register(
                      //     { name: "district_id" },
                      //     {
                      //       validate: (value) => {
                      //         return e.props.value.value === undefined ||
                      //           e.props.value.value === ""
                      //           ? false
                      //           : true;
                      //       },
                      //     }
                      //   );
                      // }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="district">ตำบล / แขวง</label>
                  </div>
                  <div className="col-lg-5">
                    <Select
                      placeholder={"เลือกตำบล/แขวง..."}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          borderColor: errors.sub_district_id
                            ? "red"
                            : "#cdd4e0",
                          "&:focus": {
                            borderColor: errors.sub_district_id
                              ? "red"
                              : "#cdd4e0",
                          },
                        }),
                      }}
                      value={sub_district_id}
                      options={option_sub_district}
                      onChange={async (e) => {
                        await setsub_district_id(e);
                        // trigger("sub_district_id");

                        setzip_code(e.zip_code);
                      }}
                      // ref={(e) => {
                      //   register(
                      //     { name: "sub_district_id" },
                      //     {
                      //       validate: (value) => {
                      //         return e.props.value.value === undefined ||
                      //           e.props.value.value === ""
                      //           ? false
                      //           : true;
                      //       },
                      //     }
                      //   );
                      // }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="zip">รหัสไปรษณีย์</label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      id="zip"
                      className="form-control"
                      name="zip"
                      type="text"
                      value={zip_code}
                      readOnly
                    />
                  </div>
                </div>
              */}
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="address">พื้นที่</label>
                  </div>
                  <div className="col-lg-5">
                    <div className="side-by-side clearfix">
                      <Select
                        placeholder={"เลือกพื้นที่..."}
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            borderColor: errors.area_id ? "red" : "#cdd4e0",
                            "&:focus": {
                              borderColor: errors.area_id ? "red" : "#cdd4e0",
                            },
                          }),
                        }}
                        value={area_id}
                        options={option_area}
                        onChange={async (e) => {
                          // console.log(e);
                          await setarea_id(e);
                          // trigger("area_id");
                        }}
                        closeMenuOnSelect={false}
                        isMulti
                        // ref={(e) => {
                        //   register(
                        //     { name: "area_id" },
                        //     {
                        //       validate: (value) => {
                        //         return e.props.value.value === undefined ||
                        //           e.props.value.value === ""
                        //           ? false
                        //           : true;
                        //       },
                        //     }
                        //   );
                        // }}
                      />
                    </div>
                  </div>
                </div>
                
                {/* <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="address">วุฒิการศึกษา</label>
                  </div>
                  <div className="col-lg-5">
                    <div className="side-by-side clearfix">
                      <Select
                        placeholder={"เลือกวุฒิการศึกษา..."}
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            borderColor: errors.degree_id ? "red" : "#cdd4e0",
                            "&:focus": {
                              borderColor: errors.degree_id ? "red" : "#cdd4e0",
                            },
                          }),
                        }}
                        value={degree_id}
                        options={option_degree}
                        onChange={async (e) => {
                          await setdegree_id(e);
                          // trigger("degree_id");
                        }}
                        // ref={(e) => {
                        //   register(
                        //     { name: "degree_id" },
                        //     {
                        //       validate: (value) => {
                        //         return e.props.value.value === undefined ||
                        //           e.props.value.value === ""
                        //           ? false
                        //           : true;
                        //       },
                        //     }
                        //   );
                        // }}
                      />
                    </div>
                  </div>
                </div>
                 */}
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="address">ประเภท</label>
                  </div>
                  <div className="col-lg-5">
                    <div className="side-by-side clearfix">
                      <Select
                        placeholder={"เลือกประเภท..."}
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            borderColor: errors.employee_type_id
                              ? "red"
                              : "#cdd4e0",
                            "&:focus": {
                              borderColor: errors.employee_type_id
                                ? "red"
                                : "#cdd4e0",
                            },
                          }),
                        }}
                        value={employee_type_id}
                        options={option_employee_type}
                        onChange={async (e) => {
                          await setemployee_type_id(e);
                          trigger("employee_type_id");
                        }}
                        ref={(e) => {
                          register(
                            { name: "employee_type_id" },
                            {
                              validate: (value) => {
                                return e.props.value.value === undefined ||
                                  e.props.value.value === ""
                                  ? false
                                  : true;
                              },
                            }
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="address">รูปแบบงานที่ต้องการ</label>
                  </div>
                  <div className="col-lg-5">
                    <div className="side-by-side clearfix">
                      <Select
                        placeholder={"เลือกงานที่ต้องการ..."}
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            borderColor: errors.service_type
                              ? "red"
                              : "#cdd4e0",
                            "&:focus": {
                              borderColor: errors.service_type
                                ? "red"
                                : "#cdd4e0",
                            },
                          }),
                        }}
                        value={service_type}
                        options={option_service}
                        onChange={async (e) => {
                          await setservice_type(e);
                        }}
                        closeMenuOnSelect={false}
                        isMulti
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="address">ทีมงาน</label>
                  </div>
                  <div className="col-lg-5">
                    <div className="side-by-side clearfix">
                      <Select
                        placeholder={"เลือกทีมงาน..."}
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            borderColor: errors.team_id ? "red" : "#cdd4e0",
                            "&:focus": {
                              borderColor: errors.team_id ? "red" : "#cdd4e0",
                            },
                          }),
                        }}
                        value={team_id}
                        options={option_team}
                        onChange={async (e) => {
                          await setteam_id(e);
                          // trigger("team_id");
                        }}
                        // ref={(e) => {
                        //   register(
                        //     { name: "team_id" },
                        //     {
                        //       validate: (value) => {
                        //         return e.props.value.value === undefined ||
                        //           e.props.value.value === ""
                        //           ? false
                        //           : true;
                        //       },
                        //     }
                        //   );
                        // }}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="address">วันที่สมัคร</label>
                  </div>
                  <div className="col-lg-5">
                    <div className="input-group input-daterange d-flex align-items-center">
                      <Controller
                        control={control}
                        name="register_date"
                        render={({ onChange, value }) => (
                          <DayPickerInput
                            value={register_date}
                            onDayChange={async (e) => {
                              await setregister_date(e);
                              trigger("register_date");
                            }}
                            placeholder={"วัน/เดือน/ปี"}
                            style={{ width: "100%" }}
                            inputProps={{
                              style: {
                                width: "100%",
                                padding: 5,
                                borderColor: errors.register_date
                                  ? "red"
                                  : "#cdd4e0",
                              },
                            }}
                            format="L"
                            formatDate={formatDate}
                            parseDate={parseDate}
                            dayPickerProps={{
                              locale: "th",
                              localeUtils: MomentLocaleUtils,
                            }}
                            ref={(e) => {
                              register(
                                { name: "register_date" },
                                {
                                  validate: (value) => {
                                    // console.log(e.props);

                                    return e.props.value === undefined ||
                                      e.props.value === ""
                                      ? false
                                      : true;
                                  },
                                }
                              );
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="credit">เครดิต(บาท)</label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      id="credit"
                      className={`form-control ${
                        errors.credit ? "error-input" : ""
                      }`}
                      name="credit"
                      type="number"
                      ref={register({ min: 0 })}
                      value={credit || 0}
                      onChange={(e) => {
                        setcredit(parseInt(e.target.value));
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="point">แต้ม</label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      id="point"
                      className={`form-control ${
                        errors.point ? "error-input" : ""
                      }`}
                      name="point"
                      type="number"
                      ref={register({ min: 0 })}
                      value={point || 0}
                      onChange={(e) => {
                        setpoint(parseInt(e.target.value));
                      }}
                    />
                  </div>
                </div> 
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="address">ประเภทยานพาหนะ</label>
                  </div>
                  <div className="col-lg-5">
                    <div className="side-by-side clearfix">
                      <Select
                        placeholder={"เลือกประเภทยานพาหนะ..."}
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            borderColor: errors.vehicle_type_id
                              ? "red"
                              : "#cdd4e0",
                            "&:focus": {
                              borderColor: errors.vehicle_type_id
                                ? "red"
                                : "#cdd4e0",
                            },
                          }),
                        }}
                        value={vehicle_type_id}
                        options={option_vehicle_type}
                        onChange={async (e) => {
                          await setvehicle_type_id(e);
                          trigger("vehicle_type_id");
                        }}
                        ref={(e) => {
                          register(
                            { name: "vehicle_type_id" },
                            {
                              validate: (value) => {
                                return e.props.value.value === undefined ||
                                  e.props.value.value === ""
                                  ? false
                                  : true;
                              },
                            }
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="license_plate">ทะเบียน</label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      id="license_plate"
                      className={`form-control ${
                        errors.license_plate ? "error-input" : ""
                      }`}
                      name="license_plate"
                      type="text"
                      ref={register({ required: true })}
                      value={license_plate}
                      onChange={(e) => {
                        setlicense_plate(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="">สี</label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      id="vehicle_color"
                      className={`form-control ${
                        errors.vehicle_color ? "error-input" : ""
                      }`}
                      name="vehicle_color"
                      type="text"
                      // ref={register({ required: true })}
                      value={vehicle_color}
                      onChange={(e) => {
                        setvehicle_color(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="">รุ่น</label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      id="vehicle_make"
                      className={`form-control ${
                        errors.vehicle_make ? "error-input" : ""
                      }`}
                      name="vehicle_make"
                      type="text"
                      // ref={register({ required: true })}
                      value={vehicle_make}
                      onChange={(e) => {
                        setvehicle_make(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="name">ใบขับขี่</label>
                  </div>
                  <div className="col-lg-5">
                    <div className="mb-2">
                      {errors.driving_picture ? (
                        <div
                          class="alert alert-danger"
                          role="alert"
                          style={{ width: "100%", padding: 5 }}
                        >
                          กรุณาอัพโหลดไฟล์
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <input
                      type="file"
                      id="driving_picture"
                      className="dropify"
                      data-max-file-size="3M"
                      accept=".png, .jpg, .jpeg"
                      data-default-file={`${img_url}${driving_picture.preview}`}
                      onChange={async (e) => {
                        if (e.target.files.length) {
                          await setdriving_picture({
                            preview: URL.createObjectURL(e.target.files[0]),
                            base64: await toBase64(e.target.files[0]),
                          });
                          trigger("driving_picture");
                        }
                      }}
                      ref={(e) => {
                        register(
                          { name: "driving_picture" },
                          {
                            validate: (value) => {
                              return driving_picture.base64 ||
                                driving_picture.preview
                                ? true
                                : false;
                            },
                          }
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="name">บัตรประชาชน</label>
                  </div>

                  <div className="col-lg-5">
                    <div className="mb-2">
                      {errors.identification_picture ? (
                        <div
                          class="alert alert-danger"
                          role="alert"
                          style={{ width: "100%", padding: 5 }}
                        >
                          กรุณาอัพโหลดไฟล์
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <input
                      type="file"
                      id="identification_picture"
                      className="dropify"
                      data-max-file-size="3M"
                      accept=".png, .jpg, .jpeg"
                      data-default-file={`${img_url}${identification_picture.preview}`}
                      onChange={async (e) => {
                        if (e.target.files.length) {
                          setidentification_picture({
                            preview: URL.createObjectURL(e.target.files[0]),
                            base64: await toBase64(e.target.files[0]),
                          });
                          // trigger("identification_picture");
                        }
                      }}
                      // ref={(e) => {
                      //   register(
                      //     { name: "identification_picture" },
                      //     {
                      //       validate: (value) => {
                      //         return identification_picture.base64 ||
                      //           identification_picture.preview
                      //           ? true
                      //           : false;
                      //       },
                      //     }
                      //   );
                      // }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="phonenumber">สถานะ</label>
                  </div>
                  <div className="col-lg-5">
                    <Select
                      placeholder={"เลือกสถานะ..."}
                      menuPlacement="top"
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          borderColor: errors.status ? "red" : "#cdd4e0",

                          "&:focus": {
                            borderColor: errors.status ? "red" : "#cdd4e0",
                          },
                        }),
                        menu: (provided, state) => ({
                          ...provided,
                          zIndex: 999,
                        }),
                      }}
                      value={status}
                      options={restaurant_status_option}
                      onChange={async (e) => {
                        await setstatus(e);
                        trigger("status");
                      }}
                      ref={(e) => {
                        register(
                          { name: "status" },
                          {
                            validate: (value) => {
                              return e.props.value.value === undefined ||
                                e.props.value.value === ""
                                ? false
                                : true;
                            },
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-10"></div>
                  <div className="col">
                    <button
                      type="submit"
                      className="btn btn-primary  mr-2  mt-2 "
                      // onClick={() => {
                      //   // validateForm();
                      // }}
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </form>
            </LoadingMask>
          </div>
        </div>
      </div>
    </div>
  );
};
