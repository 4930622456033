import React, { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { RiderDetailHead } from "./RiderDetailHead";
import Select from "react-select";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import moment from "moment";
import ReactPaginate from "react-paginate";

export const RiderDetailWithdraw = () => {
  const { id } = useParams();

  const [loading, setloading] = useState(false);

  const [data, setdata] = useState([]);
  const [current_page, setcurrent_page] = useState(1);
  const [page_count, setpage_count] = useState(0);
  const [limit] = useState(10);
  const [total_count, settotal_count] = useState(0);
  const [action, setaction] = useState({ value: "", label: "เลือก.." });

  const [childobj, setchildobj] = useState({
    id: id,
    current_page: current_page,
    limit: limit,
  });
  const callback = (data, total) => {
    setdata(data);
    let cal_page_count = Math.ceil(total / limit);
    setpage_count(cal_page_count);
    settotal_count(total);
  };

  useEffect(() => {
    setloading(true);

    setTimeout(() => {
      setloading(false);
    }, 700);
    setchildobj({
      id: id,
      current_page: current_page,
      limit: limit,
    });
  }, [current_page]);
  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap ">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-2">ประวัติพนักงาน</h4>
            <div className="d-none d-md-flex mt-1">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าแรก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">
                พนักงานส่งทั้งหมด
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <RiderDetailHead
                callback={callback}
                tabType={`withdraw`}
                paginate={childobj}
              />
              <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
                <li className="nav-item">
                  <NavLink
                    className={"nav-link "}
                    activeClassName="active"
                    to={`/rider/history/${id}`}
                  >
                    ประวัติพนักงาน
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={"nav-link "}
                    activeClassName="active"
                    to={`/rider/service/${id}`}
                  >
                    ประวัติการบริการ
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={"nav-link "}
                    activeClassName="active"
                    to={`/rider/credit/${id}`}
                  >
                    ประวัติการการเติมเครดิต
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={"nav-link "}
                    activeClassName="active"
                    to={`/rider/withdraw/${id}`}
                  >
                    ประวัติการการถอนเงิน
                  </NavLink>
                </li>
              </ul>

              <div className="tab-content" id="myTabContent">
                <div className="row">
                  <div className="col">
                    <h4 className="mt-3">ทั้งหมด {total_count} รายการ</h4>
                  </div>

                  {/* <form>
                    <div className="form-row align-items-center mb-2 mr-2 ">
                      <div className="col ml-1">
                        <div className="row">
                          <div className="ml-auto">
                            <button
                              type="button"
                              className="btn btn-primary mr-2"
                              style={{ marginTop: 23 }}
                            >
                              Export All
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form> */}
                </div>

                <LoadingMask loading={loading} text={"loading..."}>
                  <div style={{ minHeight: 500 }}>
                    <table id="dataTable" className="table">
                      <thead>
                        <tr>
                          <th>วันที่</th>
                          <th>จำนวนเงิน</th>
                          <th>ธนาคาร</th>
                          <th>หมายเลขบัญชี</th>
                          <th>สถานะ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((value, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {moment(value.created_at).format("DD/MM/YYYY")}{" "}
                                {moment(value.created_at).format("LT")}
                              </td>
                              <td>{value.amount}</td>
                              <td>{value.bank_name_th}</td>
                              <td>{value.account_number}</td>
                              <td>
                                {" "}
                                {(() => {
                                  switch (value.status) {
                                    case 0:
                                      return "ยกเลิก";
                                    case 1:
                                      return "อนุมัติ";
                                    case 2:
                                      return "รอตรวจสอบ";
                                    case 3:
                                      return "รอดำเนินการ";
                                    default:
                                      break;
                                  }
                                })()}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </LoadingMask>
                <div style={{ float: "right" }}>
                  <ReactPaginate
                    previousLabel={"← Previous"}
                    nextLabel={"Next →"}
                    breakLabel={<span className="gap">...</span>}
                    pageCount={page_count}
                    onPageChange={(e) => setcurrent_page(e.selected + 1)}
                    forcePage={current_page - 1}
                    containerClassName={"pagination"}
                    previousLinkClassName={"previous_page"}
                    nextLinkClassName={"next_page"}
                    disabledClassName={"disabled"}
                    activeClassName={"active"}
                  />
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
