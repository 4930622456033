import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";

import Axios from "axios";
import { api, header_token } from "../../config";
import moment from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";

import "moment/locale/th";
import { Link } from "react-router-dom";
import { ExportWithdrawData } from "./ExportWithdrawData";

function CustomOverlay({ classNames, selectedDay, children, ...props }) {
  return (
    <div
      className={classNames.overlayWrapper}
      style={{ marginLeft: -109 }}
      {...props}
    >
      <div className={classNames.overlay}>{children}</div>
    </div>
  );
}

export const WithdrawHistory = () => {
  const [loading, setloading] = useState(false);

  const [data, setdata] = useState([]);

  const [img] = useState("");
  const [current_page, setcurrent_page] = useState(1);
  const [page_count, setpage_count] = useState(0);
  const [limit] = useState(10);
  const [total_count, settotal_count] = useState(0);

  const [filter_from, setfilter_from] = useState("");
  const [filter_to, setfilter_to] = useState("");
  const [filter_name, setfilter_name] = useState("");
  const [filter_bank, setfilter_bank] = useState({
    value: "",
    label: "เลือก..",
  });
  const [filter_status, setfilter_status] = useState({
    value: "",
    label: "เลือก..",
  });
  const [filter_type] = useState({
    value: "",
    label: "เลือก..",
  });
  const [bank_option, setbank_option] = useState([]);

  const [export_loading, setexport_loading] = useState(false);
  const [export_data, setexport_data] = useState([]);
  const [trigger, settrigger] = useState(false);

  const exportexcel = async () => {
    setexport_loading(true);

    try {
      let start = "";
      let end = "";
      if (filter_from || filter_to) {
        start = moment(filter_from, "DD/MM/YYYY").format("YYYY-MM-DD");
        end = moment(filter_to, "DD/MM/YYYY").format("YYYY-MM-DD");
      }
      let send_data = {
        name: filter_name,
        bank_id: filter_bank.value,
        status: filter_status.value === "" ? -1 : parseInt(filter_status.value),
        type: 2,
        start_date: start,
        end_date: end,
        is_user: -1,
      };
      // // console.log(send_data);

      let { data } = await Axios.post(
        `${api}/admin/wallet/history?page=${1}&limit=${99999999999}`,
        send_data,
        header_token
      );
      // console.log("data", data);

      let _export = [];
      if (data.wallets) {
        data.wallets.forEach((value, index) => {
          let st;
          switch (value.status) {
            case 0:
              st = "ยกเลิก";
              break;
            case 1:
              st = "อนุมัติ";
              break;
            case 2:
              st = "รอตรวจสอบ";
              break;
            case 3:
              st = "รอดำเนินการ";
              break;

            default:
              break;
          }

          _export.push({
            user_id: value.user.user_id,
            name: value.user.full_name,
            amount: value.amount,
            credit: value.user.credit,
            bank: value.bank_name_th,
            account_number: value.account_number,
            account_name: value.account_name,
            branch_name: value.branch_name,
            created_at: `${moment(value.created_at).format(
              "DD/MM/YYYY"
            )} ${moment(value.created_at).format("LT")}`,
            status: st,
          });
        });
      }
      // // console.log(_export);
      setexport_data(_export);
    } catch (error) {
      // console.log(error.response);
    }

    setexport_loading(false);
  };

  const getBank = async () => {
    try {
      let { data } = await Axios.get(`${api}/bank`);
      // // console.log(data);
      let tmp = [];
      tmp.push({ value: "", label: "เลือก.." });
      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      setbank_option(tmp);
    } catch (error) {
      // console.log(error);
    }
  };

  const getData = async () => {
    setloading(true);
    try {
      let start = "";
      let end = "";
      if (filter_from || filter_to) {
        start = moment(filter_from, "DD/MM/YYYY").format("YYYY-MM-DD");
        end = moment(filter_to, "DD/MM/YYYY").format("YYYY-MM-DD");
      }
      let send_data = {
        name: filter_name,
        bank_id: filter_bank.value,
        status: filter_status.value === "" ? -1 : parseInt(filter_status.value),
        type: 2,
        start_date: start,
        end_date: end,
        is_user: -1,
      };
      // // console.log(send_data);

      let { data } = await Axios.post(
        `${api}/admin/wallet/history?page=${current_page}&limit=${limit}`,
        send_data,
        header_token
      );
      // // console.log("data", data);

      let cal_page_count = Math.ceil(data.meta.total_count / limit);
      setpage_count(cal_page_count);
      settotal_count(data.meta.total_count);

      setdata(data.wallets);
    } catch (error) {
      // console.log(error.response);
    }
    setloading(false);
  };

  useEffect(() => {
    getData();
    getBank();
  }, []);

  useEffect(() => {
    setcurrent_page(1);
    getData();
  }, [
    filter_name,
    filter_bank,
    filter_status,
    filter_type,
    filter_from,
    filter_to,
  ]);

  useEffect(() => {
    getData();
  }, [current_page]);
  return (
    <div>
      <div className="row mb-2">
        <span className="ml-2 mt-auto mb-auto h4 ">
          {" "}
          {moment().format("LL")}
        </span>
        <div className="ml-auto mr-2">
          <p className="tx-13 text-muted mb-2">ค้นหาจากช่วงเวลา</p>
          <div className="InputFromTo">
            <DayPickerInput
              value={filter_from}
              onDayChange={(e) => {
                setfilter_from(e);
                if (!filter_to) {
                  setfilter_to(e);
                }
              }}
              placeholder={"วัน/เดือน/ปี"}
              format="L"
              formatDate={formatDate}
              parseDate={parseDate}
              dayPickerProps={{
                locale: "th",
                localeUtils: MomentLocaleUtils,
              }}
            />{" "}
            ถึง{" "}
            <span className="InputFromTo-to">
              <DayPickerInput
                value={filter_to}
                onDayChange={(e) => {
                  setfilter_to(e);
                  if (!filter_from) {
                    setfilter_from(e);
                  }
                }}
                overlayComponent={CustomOverlay}
                placeholder={"วัน/เดือน/ปี"}
                format="L"
                formatDate={formatDate}
                parseDate={parseDate}
                dayPickerProps={{
                  locale: "th",
                  localeUtils: MomentLocaleUtils,
                }}
              />
            </span>
            <button
              type="button"
              className="btn btn-primary mr-2 ml-2"
              style={{ marginTop: -2, padding: `5px 10px 5px 10px` }}
              onClick={async () => {
                if (!trigger && !export_loading) {
                  await exportexcel();
                  await settrigger(true);
                }
              }}
            >
              {export_loading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : null}
              {` `}Export
            </button>
            <ExportWithdrawData
              data={export_data}
              trigger={trigger}
              settrigger={settrigger}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <h4 className="mb-4 mt-2">ทั้งหมด {total_count} รายการ</h4>
                </div>

                <div className="form-row align-items-center mb-2 mr-2 ">
                  <div className=" mr-2" style={{ width: 200 }}>
                    <span className="tx-13 text-muted">ค้นหา</span>
                    <input
                      type="text"
                      className="form-control- global_filter"
                      id="global_filter"
                      placeholder="ชื่อ"
                      value={filter_name}
                      onChange={(e) => {
                        setfilter_name(e.target.value);
                      }}
                    />
                  </div>

                  <div className="col ml-1 mr-2">
                    <div className="row">
                      <div style={{ width: 200 }}>
                        <span className="tx-13 text-muted">ธนาคาร</span>
                        <Select
                          value={filter_bank}
                          onChange={(e) => {
                            setfilter_bank(e);
                          }}
                          options={bank_option}
                          placeholder={"ธนาคาร.."}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col ml-2 mr-2">
                    <div className="row">
                      <div style={{ width: 150 }}>
                        <span className="tx-13 text-muted">สถานะ</span>
                        <Select
                          value={filter_status}
                          onChange={(e) => {
                            setfilter_status(e);
                          }}
                          options={[
                            { label: "ยกเลิก", value: "0" },
                            { label: "อนุมัติ", value: 1 },
                            { label: "รอตรวจสอบ", value: 2 },
                            { label: "รอดำเนินการ", value: 3 },
                          ]}
                          placeholder={"สถานะ.."}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="col mr-2 ml-2">
                      <div className="row">
                        <div style={{ width: 120 }} className="">
                          <span className="tx-13 text-muted">ดำเนินการ</span>
                          <Select
                            value={action}
                            onChange={(e) => {
                              setaction(e);
                            }}
                            options={wallet_action}
                            placeholder={"ดำเนินการ.."}
                          />
                        </div>
                      </div>
                    </div>
                   */}
                </div>
              </div>

              <LoadingMask loading={loading} text={"loading..."}>
                <div style={{ minHeight: 500 }}>
                  <div className="table-responsive">
                    <table id="dataTable" className="table ">
                      <thead>
                        <tr>
                          <th style={{ minWidth: 50 }}>รหัส</th>
                          <th style={{ minWidth: 300 }}>ชื่อ-นามสกุล</th>
                          <th style={{ minWidth: 150 }}>จำนวนเงิน</th>
                          <th style={{ minWidth: 150 }}>เครดิตที่มี</th>
                          <th style={{ minWidth: 200 }}>ธนาคาร</th>
                          <th style={{ minWidth: 200 }}>หมายเลขบัญชี</th>
                          <th style={{ minWidth: 300 }}>ชื่อบัญชี</th>
                          <th style={{ minWidth: 200 }}>สาขา</th>
                          <th style={{ minWidth: 200 }}>วันที่</th>
                          <th style={{ minWidth: 150 }}>สถานะ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((value, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {value.user
                                  ? value.user.user_id
                                  : value.restaurant.restaurant_id}
                              </td>
                              <td>
                                <Link
                                  to={
                                    value.user
                                      ? `/rider/withdraw/${value.user.id}`
                                      : `/restaurant/detail/${value.restaurant.id}/withdraw`
                                  }
                                >
                                  {value.user
                                    ? value.user.full_name
                                    : value.restaurant.name_th}
                                </Link>
                              </td>
                              <td className="text-success">
                                {value.amount.toLocaleString("de")}
                              </td>
                              <td>
                                {value.user
                                  ? `${value.user.credit.toLocaleString("de")}`
                                  : `${value.restaurant.credit.toLocaleString("de")}`}
                              </td>
                              <td>{value.bank_name_th}</td>
                              <td>{value.account_number}</td>
                              <td>{value.account_name}</td>
                              <td>{value.branch_name}</td>
                              <td>
                                {moment(value.created_at).format("DD/MM/YYYY")}{" "}
                                {moment(value.created_at).format("LT")}
                              </td>
                              <td>
                                {(() => {
                                  switch (value.status) {
                                    case 0:
                                      return "ยกเลิก";
                                    case 1:
                                      return "อนุมัติ";
                                    case 2:
                                      return "รอตรวจสอบ";
                                    case 3:
                                      return "รอดำเนินการ";
                                    default:
                                      break;
                                  }
                                })()}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </LoadingMask>
              <div className="mt-2" style={{ float: "right" }}>
                <ReactPaginate
                  previousLabel={"← Previous"}
                  nextLabel={"Next →"}
                  breakLabel={<span className="gap">...</span>}
                  pageCount={page_count}
                  onPageChange={(e) => setcurrent_page(e.selected + 1)}
                  forcePage={current_page - 1}
                  containerClassName={"pagination"}
                  previousLinkClassName={"previous_page"}
                  nextLinkClassName={"next_page"}
                  disabledClassName={"disabled"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="showModdal" className="modal  show-img" role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content" style={{ width: 800 }}>
            <div className="modal-header">
              <span>รูปภาพ</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <img
                src={img ? img : ""}
                style={{ maxWidth: 600, display: "block", margin: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
